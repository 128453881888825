import { FunctionComponent } from 'react';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/freightPage.css';

const FreightPage: FunctionComponent = () => {
  const router = useHistory();
  const buttons: Array<{ name: string; href: string; icon: string }> = [
    { name: 'Flete', href: '/mantenedores/porteo/gastos-porteo', icon: 'dollyGreenIcon' },
    { name: 'Extras', href: '/mantenedores/porteo/extras-porteo', icon: 'packageBlueIcon' },
    {
      name: 'Diferencia Tarifa',
      href: '/mantenedores/diferencia-tarifa',
      icon: 'mathOperationsIcon'
    },
    {
      name: 'Retención Contingencia',
      href: '/reportes/porteo/retencion-contingencia',
      icon: 'piggyBankIcon'
    },
    {
      name: 'Estado Financiero',
      href: '/reportes/porteo/estado-financiero',
      icon: 'moneyIcon'
    }
  ];
  return (
    <div className='freigh-page-container'>
      <BackButton route='/modulos' />
      <div className='freigh-page-body'>
        <h2 className='freigh-page-title'>Planificación Porteo</h2>

        <GenericIcons icon='porteoIcon' imageStyle={{ height: '55%' }} />

        <div className='freigh-page-buttons-container'>
          {buttons.map((btn, idx) => (
            <div className='freigh-page-button' key={idx} onClick={() => router.push(btn.href)}>
              <GenericIcons icon={btn.icon} /> <p>{btn.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FreightPage;
