import { Box, Stack } from '@mui/material';
import { Ifac } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const IfacEntity: WebEntity<Ifac> = {
  name: 'ifac',
  endpoint: 'ifac',
  referenceColumn: 'ifac',

  tableColumns: [
    {
      header: 'Periodo',
      accessorKey: 'period',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'date',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.date, 'DD-MM-YYYY', true)
    },
    {
      header: 'A023',
      accessorKey: 'a023',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a023, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a023 ? acc + Number(obj.a023) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'A050',
      accessorKey: 'a050',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a050, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a050 ? acc + Number(obj.a050) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'A061',
      accessorKey: 'a061',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a061, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a061 ? acc + Number(obj.a061) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'A071',
      accessorKey: 'a071',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a071, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a071 ? acc + Number(obj.a071) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'A080',
      accessorKey: 'a080',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a080, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a080 ? acc + Number(obj.a080) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'A090',
      accessorKey: 'a090',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.a090, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.a090 ? acc + Number(obj.a090) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.total, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Ifac) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    }
  ]
};

export const IFAC_COLUMNS = {
  company: 'Sociedad',
  companyName: 'Descripción sociedad',
  date: 'Fecha',
  period: 'Periodo (año, mes)',
  timeRouteIdCcu: 'Ruta',
  itemGroup: 'Grupo de artículos',
  materialDocumentDateOfReceipt: 'Fecha Doc. Material Recepción',
  palletQuantity: 'Cantidad Pallet',
  netValue: 'Valor Neto',
  carrierDescription: 'Descripción transportista',
  classification: 'CLASIFICACION'
};

export default IfacEntity;
