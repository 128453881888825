import { Stack } from '@mui/material';
import { FileReaderColumnProps } from '../components/FileReader';
import { PresupuestoVolumen } from '../types';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const PresupuestoVolumenEntity: WebEntity<PresupuestoVolumen> = {
  name: 'presupuestoVolumen',
  endpoint: 'volumen/presupuesto',
  referenceColumn: 'indicador',
  tableColumns: [
    {
      header: 'Indicador',
      accessorKey: 'indicador',
      columnType: 'text',
      accessorFn: (row) => {
        const fecha = new Date(dateFormatFn(row.fecha, 'MM-DD-YYYY', true));
        const year = fecha.getFullYear();
        return `${year}P`;
      },
      valueToExport: (row) => {
        const fecha = new Date(dateFormatFn(row.fecha, 'MM-DD-YYYY', true));
        const year = fecha.getFullYear();
        return `${year}P`;
      }
    },
    {
      header: 'Mes',
      accessorKey: 'mes',
      columnType: 'text',
      accessorFn: (row) => {
        const fecha = new Date(dateFormatFn(row.fecha, 'MM-DD-YYYY', true));
        const month = fecha.getMonth();
        return spanishMonthNames[month].slice(0, 3);
      },
      valueToExport: (row) => {
        const fecha = new Date(dateFormatFn(row.fecha, 'MM-DD-YYYY', true));
        const month = fecha.getMonth();
        return spanishMonthNames[month].slice(0, 3);
      }
    },
    {
      header: 'Deposito',
      accessorKey: 'deposito',
      columnType: 'numeric'
    },
    {
      header: 'zonatxt',
      accessorKey: 'zoneName',
      columnType: 'text'
    },
    {
      header: 'Categoria',
      accessorKey: 'categoria',
      columnType: 'text'
    },
    {
      header: 'UEN',
      accessorKey: 'uen',
      columnType: 'numeric'
    },
    {
      header: 'Envase',
      accessorKey: 'envase',
      columnType: 'text'
    },
    {
      header: 'Fisicas',
      accessorKey: 'fisicas',
      columnType: 'numeric',
      accessorFn: (row) => row.fisCalaf_1_4,
      Cell: ({ row }) => (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {formatStringNumber(row.original.fisCalaf_1_4, 0, 2)}
        </div>
      ),
      valueToExport: (row) => row.fisCalaf_1_4,
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PresupuestoVolumen) =>
            obj.fisCalaf_1_4 ? acc + Number(obj.fisCalaf_1_4) : acc,
          0
        );
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'HL',
      accessorKey: 'hl',
      columnType: 'numeric'
    },
    {
      header: 'Fis_Calaf1/4',
      accessorKey: 'fisCalaf_1_4',
      columnType: 'numeric',
      accessorFn: (row) => row.fisCalaf_1_4,
      Cell: ({ row }) => (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {formatStringNumber(row.original.fisCalaf_1_4, 0, 2)}
        </div>
      ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PresupuestoVolumen) =>
            obj.fisCalaf_1_4 ? acc + Number(obj.fisCalaf_1_4) : acc,
          0
        );
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Zona',
      accessorKey: 'zoneGlobalName',
      columnType: 'text'
    },
    {
      header: 'Responsable',
      accessorKey: 'responsable',
      columnType: 'text'
    },
    {
      header: 'CD',
      accessorKey: 'distributionCenterName',
      columnType: 'text'
    },
    {
      header: 'Grupo',
      accessorKey: 'groupName',
      columnType: 'text'
    },
    {
      header: 'Tipo_Envase',
      accessorKey: 'tipoEnvase',
      columnType: 'text'
    }
  ]
};

export default PresupuestoVolumenEntity;

export const PRESUPUESTO_VOLUMEN_COLUMNS: FileReaderColumnProps = {
  indicador: 'Indicador',
  mes: 'Mes',
  deposito: 'Deposito',
  zonatxt: 'zonatxt',
  categoria: 'Categoria',
  uen: 'UEN',
  envase: { name: 'Envase', required: false, export: true, upload: true },
  fisicas: 'Fisicas',
  hl: { name: 'HL', required: false, export: true, upload: true },
  fisCalaf_1_4: 'Fis_Calaf1/4',
  zona: 'Zona',
  responsable: 'Responsable',
  cd: 'CD',
  grupo: 'Grupo',
  tipoEnvase: { name: 'Tipo_Envase', required: false, export: true, upload: true }
};
