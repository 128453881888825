import { DistributionCenterBudgetVolume } from '../types';
import { WebEntity } from './types';

const DistributionCenterBudgetVolumeEntity: WebEntity<DistributionCenterBudgetVolume> = {
  name: 'distributionCenterBudgetVolume',
  endpoint: 'volumen/presupuesto/distributionCenterBudgetVolume',
  referenceColumn: 'distributionCenterId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true,
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Zona Global',
      accessorKey: 'distributionCenterName',
      columnType: 'text'
    }
  ]
};

export default DistributionCenterBudgetVolumeEntity;
