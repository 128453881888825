import { ZoneGlobalBudgetVolume } from '../types';
import { WebEntity } from './types';

const ZoneGlobalBudgetVolumeEntity: WebEntity<ZoneGlobalBudgetVolume> = {
  name: 'zoneGlobalBudgetVolume',
  endpoint: 'volumen/presupuesto/zoneGlobalBudgetVolume',
  referenceColumn: 'zoneGlobalId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true,
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalName',
      columnType: 'numeric'
    }
  ]
};

export default ZoneGlobalBudgetVolumeEntity;
