import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';
import { ImageFormats, SimpleObject } from '../types';
import { ReactElement, ReactNode, RefObject } from 'react';
import {
  MRT_Cell_WithOriginal,
  MRT_Column_WithOriginal,
  MRT_Row_WithOriginal,
  MRT_TableInstance_WithOriginal
} from '../components/types';

export enum FieldTypes {
  Text = 'text',
  Email = 'email',
  Rut = 'rut',
  Password = 'password',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  Reference = 'reference',
  Photo = 'photo',
  MultiReference = 'multiReference',
  Custom = 'custom'
}

export type UpdateDependencyFunc = (
  value: any, // The value in the formfield
  getField: (fieldName: string) => any, // function to get a field from the entity
  setLoadings: (fields: any[], status: boolean) => void, // set a loading of a field
  updateFetchedFields: (map: SimpleObject) => void, // function to update 'fetchedFields' of GenericForm
  updateFieldValue: (fieldSelector: string, value: any, isMultiForm?: boolean) => void, // function to update the value from the formfield
  getFieldValue: (fieldSelector: string) => any, // function to get the value from the specified field
  getRow: () => any // function to get the row of an entity field
) => Promise<void>;

export type WebEntityName =
  | 'user'
  | 'userType'
  | 'notification'
  | 'notificationType'
  | 'map'
  | 'costCenter'
  | 'zone'
  | 'budget'
  | 'budgetType'
  | 'estimatedClosing'
  | 'pendingBudget'
  | 'categories'
  | 'subCostCenter'
  | 'dictionaryCc'
  | 'areaManager'
  | 'expenses'
  | 'zoneGlobal'
  | 'pendingEstimatedClose'
  | 'globalEstimatedClose'
  | 'generalZoneReport'
  | 'generalReport'
  | 'provision'
  | 'reclassification'
  | 'refusedEstimatedClose'
  | 'hfm'
  | 'volumen'
  | 'presupuestoVolumen'
  | 'zoneGlobalBudgetVolume'
  | 'zoneBudgetVolume'
  | 'distributionCenterBudgetVolume'
  | 'groupBudgetVolume'
  /* PORTEO */
  | 'carrier'
  | 'managementType'
  | 'carrierType'
  | 'gloss'
  | 'zonePorting'
  | 'concept'
  | 'subConcept'
  | 'tariff'
  | 'freightChargesQ1'
  | 'freightChargesQ2'
  | 'equivalentBox'
  | 'contingencyWithholding'
  | 'contingencyWithholdingInconsistencies'
  | 'fareDifference'
  | 'fareDifferenceEntrepreneurs'
  | 'fareDifferenceSummary'
  | 'estimatedClosingPorting'
  | 'majorPorting'
  | 'permanentTruckQ1'
  | 'permanentTruckQ2'
  | 'carrierZoneGloss'
  | 'freightGloss'
  | 'contractorTariff'
  | 'freightExtra'
  | 'freightExtraResume'
  | 'freightProvision'
  | 'freightExtraGlSap'
  | 'freightExtraInconsistencies'
  /* ACARRREO */
  | 'timeRoute'
  | 'ifac'
  | 'foreignExchange'
  | 'foreignExchangeEstimated'
  | 'foreignExchangeResume'
  | 'carrierRouteCarriage'
  | 'carrierTypeCarriage'
  | 'tariffCarriage'
  | 'ifacSimulated'
  | 'freightTimeMaster'
  | 'extraCarriage'
  | 'reportUEN'
  | 'shipmentCarriageQ1'
  | 'shipmentCarriageQ2'
  | 'shipmentAuditingCarriageQ1'
  | 'shipmentAuditingCarriageQ2'
  | 'shipmentCarriagePXQQ1'
  | 'shipmentCarriagePXQQ2'
  | 'resumeShipmentCarriageQ1'
  | 'resumeShipmentCarriageQ2'
  | 'polynomial'
  | 'provisionCarriage'
  | 'budgetCarriageIngresoAcarreo'
  | 'budgetCarriageGastoRepro'
  | 'budgetCarriageGastoAcarreo'
  | 'budgetCarriageVolumenRepro'
  | 'carriageResume'
  /* Reportes */
  | 'reporteGastos'
  | 'gastosPieChart1'
  | 'gastosPieChart2'
  | 'gastosPieChartComparison'
  | 'reporteVolumen'
  | 'volumenPieChart1'
  | 'volumenPieChart2'
  | 'volumenPieChartComparison';

export type TableColumn<T> = {
  name: string;
  selector: string;
  wrap?: boolean;
  omit?: boolean;
  center?: boolean;
  sortable?: boolean;
  format?: (entry: T) => string;
  cell?: (row: T) => JSX.Element;
  allowOverflow?: boolean;
  button?: boolean;
  grow?: number;
  width?: string;
};

export type Fields<T> = {
  name: string;
  selector: string;
  type: FieldTypes;
  CustomField?: (
    onChange: (inputValue: any) => void,
    value: any,
    fetchedFields: { [key: string]: Array<SimpleObject> }
  ) => ReactElement<any, any>;
  textUpperCase?: boolean;
  required?: boolean;
  multiInput?: {
    multiField: boolean;
    isDuplicable: boolean;
    setDefaultValueOnNewRow?: {
      // True, copy the value of this field as default on every new row you add
      previous: boolean; // True, copy previus field. False, copy first field
    };
  };
  disabled?: boolean;
  default?: boolean;
  onlyAdmin?: boolean;
  hidden?: boolean;
  defaultValue?: string | Date;
  updateDependency?: UpdateDependencyFunc;
  timerUpdateDependency?: boolean;
  timezone?: string;
  groupNumber?: number;
  columnClassName?: string;
  reference?: {
    name: string;
    endpoint?: string;
    endpointQuery?: (rowEntity: T) => SimpleObject; // NOTE: returns the object with the data that you want to send to the endpoint
    select: string;
    show: string;
    filter?: SimpleObject;
    data?: SimpleObject[];
  };
  constraints?: {
    min: number;
    max: number;
    format: ImageFormats;
  };
};

type EditableFields<T> = Fields<T>;

export type CustomButtonNames = {
  addButtonName?: string;
  multiAddButtonName?: string;
  deleteButtonName?: string;
};

export type CustomDataActions = {
  addModalTitle?: string;
  editModalTitle?: string;
};

export type ColumnType =
  | 'text'
  | 'boolean'
  | 'numeric'
  | 'date'
  | 'dateTime'
  | 'dateUTC'
  | 'dateTimeUTC'
  | 'percentage';

export type ExtendedColumnDef<T> = MRT_ColumnDef<MRT_RowData, any> & {
  accessorKey: keyof T;
  columnType: ColumnType;
  AggregatedCell?: (props: {
    cell: MRT_Cell_WithOriginal<T>;
    column: MRT_Column_WithOriginal<T>;
    row: MRT_Row_WithOriginal<T>;
    table: MRT_TableInstance_WithOriginal<T>;
  }) => ReactNode;
  Cell?: (props: {
    cell: MRT_Cell_WithOriginal<T>;
    column: MRT_Column_WithOriginal<T>;
    renderedCellValue: ReactNode;
    row: MRT_Row_WithOriginal<T>;
    rowRef?: RefObject<HTMLTableRowElement>;
    table: MRT_TableInstance_WithOriginal<T>;
  }) => ReactNode;
  omitExport?: boolean;
  valueToExport?: (row: T) => string;
};

export type WebEntity<T> = {
  name: WebEntityName;
  endpoint: string;
  customButtonNames?: CustomButtonNames;
  customDataActions?: CustomDataActions;
  referenceColumn: string;
  tableColumns: ExtendedColumnDef<T>[];
  fields?: Fields<T>[];
  editableFields?: EditableFields<T>[];
};

export type WebEntityKeys<T> = keyof WebEntity<T>;
