import { FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Maintainers } from './';
import '../css/majorPortingPage.css';
import GenericModal from '../components/generics/GenericModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileReaderCSV from '../components/FileReader';
import GenericInfoTag from '../components/generics/GenericInfoTag';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { MajorPortingType, SimpleObject } from '../types';
import { MAJOR_PORTING_COLUMNS } from '../entities/MajorPortingEntity';
import { massiveLoadMajorPorting } from '../actions/middleActions';

const MajorPortingPage: FunctionComponent<{
  massiveLoadMajorPorting: (data: Array<SimpleObject>, query: SimpleObject) => any;
}> = ({ massiveLoadMajorPorting }) => {
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [value, setValue] = useState('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const MajorPortingCRUDMaintainer = useMemo(() => Maintainers.MajorPortingCRUDMaintainer, []);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      return;
    }

    const res = await massiveLoadMajorPorting(data, {
      majorPortingType:
        value === '1' ? MajorPortingType.contingencyWithholding : MajorPortingType.severancePay
    });

    if (res) {
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div className='major-porting-page-container'>
      <GenericModal
        title={'CARGA MASIVA MAYOR PORTEO'}
        buttonName={'CARGA MASIVA MAYOR PORTEO'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='major-porting-page-massive-load-modal-container'>
          <GenericInfoTag
            alert={`Los Montos negativos se asocian como Ret. Contingencia y los positivos como Finiquitos`}
            alertTitle='Importante!'
          />

          <FileReaderCSV columns={MAJOR_PORTING_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='major-porting-page-body'>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              textColor='inherit'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#64a70b'
                }
              }}>
              <Tab label='Retención por Contingencia' value='1' />
              <Tab label='Finiquitos' value='2' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <MajorPortingCRUDMaintainer
              title='Retención por Contingencia'
              query={{ majorPortingType: MajorPortingType.contingencyWithholding }}
            />
          </TabPanel>
          <TabPanel value='2'>
            <MajorPortingCRUDMaintainer
              title='Finiquitos'
              query={{ majorPortingType: MajorPortingType.severancePay }}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};
export const MajorPortingPageConnected = connect(null, {
  massiveLoadMajorPorting
})(MajorPortingPage);
