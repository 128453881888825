import { Stack } from '@mui/material';
import { Budget } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const BudgetEntity: WebEntity<Budget> = {
  name: 'budget',
  endpoint: 'budget',
  referenceColumn: 'budget',
  tableColumns: [
    {
      header: 'Total General',
      accessorKey: 'totalGeneral',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.totalGeneral)
    },
    {
      header: 'Nombre de categoria',
      id: 'categoriesName',
      accessorKey: 'categoriesName',
      columnType: 'text'
    },
    {
      header: 'Cuenta SAP',
      accessorKey: 'accountSap',
      columnType: 'text'
    },

    {
      header: 'Nombre CC SAP',
      accessorKey: 'accountDescription',
      columnType: 'text'
    },
    {
      header: 'CC SAP',
      accessorKey: 'costCenterSap',
      columnType: 'text'
    },
    {
      header: 'Centro de beneficio',
      accessorKey: 'costCenterName',
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text'
    },
    {
      header: 'Sub Zona',
      accessorKey: 'subCategoriesDescription',
      columnType: 'text'
    },
    {
      header: 'Indicador',
      accessorKey: 'budgetTypeName',
      columnType: 'text'
    },
    {
      header: 'Enero',
      accessorKey: 'enero',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.enero),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.enero ? acc + Number(obj.enero) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'febrero',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.febrero),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.febrero ? acc + Number(obj.febrero) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'marzo',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.marzo),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.marzo ? acc + Number(obj.marzo) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'abril',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.abril),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.abril ? acc + Number(obj.abril) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'mayo',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.mayo),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.mayo ? acc + Number(obj.mayo) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'junio',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.junio),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.junio ? acc + Number(obj.junio) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'julio',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.julio),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.julio ? acc + Number(obj.julio) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'agosto',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.agosto),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.agosto ? acc + Number(obj.agosto) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'septiembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.septiembre),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.septiembre ? acc + Number(obj.septiembre) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'octubre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.octubre),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.octubre ? acc + Number(obj.octubre) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'noviembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.noviembre),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.noviembre ? acc + Number(obj.noviembre) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'diciembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.diciembre),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Budget) => (obj.diciembre ? acc + Number(obj.diciembre) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    }
  ],

  fields: [],

  editableFields: []
};

export const BUDGET_COLUMNS = {
  costCenterSap: 'CC',
  accountSap: 'Cta',
  budgetTypeIndicator: 'Indicador',
  month: 'Mes',
  costCenterName: 'Nombre CC',
  zoneDescrp: 'Zona',
  userUsernameLevelOne: { name: 'Nivel 1', required: false, export: true },
  userUsernameLevelTwo: { name: 'Nivel 2', required: false, export: true },
  userUsernameLevelThree: { name: 'Nivel 3', required: false, export: true },
  userUsernameLevelFour: { name: 'Nivel 4', required: false, export: true },
  area: { name: 'Area', required: false, export: true },
  categoriesName: 'Categoria',
  accountDescription: 'Descrip_Cuenta',
  total: 'Monto',
  ccPs: { name: 'CC_PS', required: false, export: true },
  ctaPs: { name: 'CTA_PS', required: false, export: true }
};

export default BudgetEntity;
