import { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { useHistory, useParams } from 'react-router-dom';
import { UserLevels, UserTypeValues, globalZones } from '../types';
import BackButton from '../components/BackButton';
import Swal from 'sweetalert2';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/operationsPage.css';

interface ButtonProps {
  onClick: () => void;
  icon: string;
  title: string;
  allowedUserTypes: Array<UserTypeValues>;
  allowedUserLevels: Array<UserLevels>;
}

interface Operations {
  estimatedClosing: ButtonProps[];
  administration: ButtonProps[];
  provision: ButtonProps[];
  reclassification: ButtonProps[];
  generalZone: ButtonProps[];
}

const OperationsPage: FunctionComponent<{
  auth: AuthState;
}> = ({ auth }) => {
  const router = useHistory();
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  if (
    !params ||
    !params.optionType ||
    !params.globalZone ||
    !params.level ||
    !params.subCategories ||
    (params.globalZone === 'Centro' && !params.zones)
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrió un error al obtener los parametros. Contacta al administrador'
    });
    router.push('/');
  }

  const optionType = params.optionType;
  const globalZone = params.globalZone;
  const level = params.level;
  const subCategories = params.subCategories.split(',');
  const zones = params.zones ? params.zones.split(',') : [];

  const buttons: Operations = {
    estimatedClosing: [
      {
        title: 'Estimado de Cierre',
        icon: 'calculatorIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado de Cierre Pendientes',
        icon: 'warningCircleIcon',
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-pendiente/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado de Cierre Rechazados',
        icon: 'xCircleIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-rechazado/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado de Cierre Global',
        icon: 'listChecksIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-global/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    administration: [
      {
        title: 'Libro Mayor',
        icon: 'receiptIcon',
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/SD/gastos/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      },
      {
        title: 'HFM',
        icon: 'chalkboardIcon',
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/SD/HFM/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      }
    ],
    provision: [
      {
        title: 'Provisiones',
        icon: 'calendarAltIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/provisiones/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      },
      {
        title: 'Provisiones Pendientes',
        icon: 'calendarMinusIcon',
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-pendientes/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Provisiones Rechazadas',
        icon: 'calendarTimesIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-rechazadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Provisiones Aprobadas',
        icon: 'calendarCheckIcon',
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-aprobadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    reclassification: [
      {
        title: 'Reclasificaciones',
        icon: 'userIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Pendientes Reclasificaciones',
        icon: 'userMinusIcon',
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-pendientes/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Reclasificaciones Rechazados',
        icon: 'userTimesIcon',
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-rechazadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Reclasificaciones Aprobadas',
        icon: 'userCheckIcon',
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-aprobadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    generalZone: [
      {
        title: 'Cierre General Zona',
        icon: 'foldersIcons',
        allowedUserLevels: [UserLevels.levelOne, UserLevels.levelTwo],
        allowedUserTypes: [],
        onClick: () =>
          router.push(
            `/mantenedores/totales-generales/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ]
  };

  const ItemButton: FunctionComponent<{
    onClick: () => void;
    icon: JSX.Element;
    title: string;
  }> = ({ onClick, icon, title }) => {
    return (
      <div className='operations-container-button'>
        <button className='operations-button' onClick={onClick}>
          {icon}
          {title}
        </button>
      </div>
    );
  };

  const checkButtonAccess = (button: ButtonProps) =>
    button.allowedUserTypes.some((uT) => auth.user?.userTypeName.includes(uT)) ||
    button.allowedUserLevels.some((uL) => uL === level);

  const Section: FunctionComponent<{ title: string; img: string; buttons: ButtonProps[] }> = ({
    title,
    img,
    buttons
  }) => (
    <div className='operations-section-container'>
      <div className='operations-section-title-container'>
        <h3>{title}</h3>
        <GenericIcons icon={img} />
      </div>
      <div className='operations-section-buttons-container'>
        {buttons.map((b, idx) =>
          checkButtonAccess(b) ? (
            <div
              key={`${title}-btn-${idx}`}
              className='operations-section-button'
              onClick={b.onClick}>
              <GenericIcons icon={b.icon} />
              <p>{b.title}</p>
            </div>
          ) : null
        )}
      </div>
    </div>
  );

  return (
    <div className='operations-container'>
      <div>
        <BackButton
          route={
            globalZone !== 'Centro'
              ? `/mapa-zona-global/${optionType}`
              : `/mapa-zona-centro/${optionType}/${globalZone}/${level}/${subCategories}`
          }
        />
        <div className='operations-header'>
          <h2 className='operations-title'>Estimados y cierres del mes</h2>
          <div className='operations-info'>
            <p>
              Zona Global: <span>{globalZone}</span>
            </p>
            {globalZone === 'Centro' && zones.length === 1 ? (
              <p>
                Zona: <span>{zones}</span>
              </p>
            ) : globalZone === 'Centro' && zones.length > 1 ? (
              <button
                onClick={() =>
                  Swal.fire({
                    icon: 'info',
                    title: 'Zonas',
                    html: `<p>${zones.join(' - ')}</p>`
                  })
                }>
                Mis Zonas
              </button>
            ) : subCategories.length === 1 ? (
              <p>
                Sub Categoria: <span>{subCategories}</span>
              </p>
            ) : (
              <button
                onClick={() =>
                  Swal.fire({
                    icon: 'info',
                    title: 'Sub categorias',
                    html: `<p>${subCategories.join(' - ')}</p>`
                  })
                }>
                Mis Sub categorias
              </button>
            )}
          </div>
        </div>
      </div>

      <div className='operations-body'>
        {[...buttons.estimatedClosing, ...buttons.administration].some((b) =>
          checkButtonAccess(b)
        ) && (
          <div className='operations-article'>
            {buttons.estimatedClosing.some((b) => checkButtonAccess(b)) && (
              <Section
                title='Estimados de Cierre'
                img='cashIcon'
                buttons={buttons.estimatedClosing}
              />
            )}

            {buttons.administration.some((b) => checkButtonAccess(b)) && (
              <Section title='Administrar' img='paymentIcon' buttons={buttons.administration} />
            )}
          </div>
        )}

        {[...buttons.provision, ...buttons.reclassification].some((b) => checkButtonAccess(b)) && (
          <Fragment>
            <div className='operations-subtitle-container'>
              <h2 className='operations-subtitle'>Cierre mes</h2>
            </div>

            <div className='operations-article'>
              {buttons.provision.some((b) => checkButtonAccess(b)) && (
                <Section title='Provisión' img='provisionIcon' buttons={buttons.provision} />
              )}

              {buttons.reclassification.some((b) => checkButtonAccess(b)) && (
                <Section
                  title='Reclasificación'
                  img='reclasificacionIcon'
                  buttons={buttons.reclassification}
                />
              )}
            </div>
          </Fragment>
        )}

        {buttons.generalZone.some((b) => checkButtonAccess(b)) && (
          <div className='operations-article'>
            {buttons.generalZone.some((b) => checkButtonAccess(b)) && (
              <Section title='General Zona' img='generalZonaIcon' buttons={buttons.generalZone} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(OperationsPage);
