import { CSSProperties } from 'react';
import update from '../../img/CloudArrowUp-r.svg';
import datafy from '../../img/Logo.svg';
import logout from '../../img/SignOut-r.svg';
import avatar from '../../img/UserCircle-r.svg';
import edit from '../../img/PencilLine-r.svg';
import filter from '../../img/Funnel-r.svg';
import add from '../../img/Plus-r.svg';
import arrowLeft from '../../img/arrowLeftMenu.png';
import manteiner from '../../img/FolderNotch-d.svg';
import count from '../../img/ListNumbers-r.svg';
import arrow from '../../img/ArrowLineRight-s.svg';
import info from '../../img/Info-r.svg';
import infoWhite from '../../img/Info-rWhite.svg';
import report from '../../img/ClipboardText.svg';
import table from '../../img/Table-r.svg';
import trash from '../../img/Trash-r.svg';
import trashWhite from '../../img/trash_white.png';
import Eye from '../../img/Eye-r.svg';
import duplicate from '../../img/duplicate.png';
import duplicateWhite from '../../img/duplicate_White.png';
import CaretRightIcon from '../../img/CaretRightIcon.svg';
import HouseIcon from '../../img/HouseIcon.svg';
import FilterIcon from '../../img/filter-icon.png';
import pageNotFound from '../../img/pageNotFound.svg';
import truckIcon from '../../img/truck-icon.svg';
import truckIcon2 from '../../img/truckIcon2.svg';
import calendarIcon from '../../img/calendarIcon.svg';
import manageMoneyIcon from '../../img/manage-money-pana.svg';
import checkingBoxesIcon from '../../img/checking-boxes-bro.svg';
import coinsIcon from '../../img/coins-pana.svg';
import acarreoIcon from '../../img/acarreo.svg';
import moneyStressIcon from '../../img/money-stress-pana.svg';
import clipboardTextIcon from '../../img/clipboard-text-r.svg';
import signInIcon from '../../img/sign-in-r.svg';
import packageIcon from '../../img/package-r.svg';
import moneyIcon from '../../img/money-r.svg';
import dollyIcon from '../../img/dolly-solid.svg';
import truckRIcon from '../../img/truck-r.svg';
import porteoIcon from '../../img/porteo.svg';
import mathOperationsIcon from '../../img/math-operations-r.svg';
import packageBlueIcon from '../../img/package-blue-r.svg';
import piggyBankIcon from '../../img/piggy-bank-solid.svg';
import dollyGreenIcon from '../../img/dolly-solid-green.svg';
import cashIcon from '../../img/cash.svg';
import paymentIcon from '../../img/payment.svg';
import calculatorIcon from '../../img/calculator-r.svg';
import chalkboardIcon from '../../img/chalkboard-r.svg';
import listChecksIcon from '../../img/list-checks-r.svg';
import receiptIcon from '../../img/receipt-r.svg';
import warningCircleIcon from '../../img/warning-circle-r.svg';
import xCircleIcon from '../../img/x-circle-r.svg';
import provisionIcon from '../../img/provision.svg';
import reclasificacionIcon from '../../img/reclasificacion.svg';
import calendarAltIcon from '../../img/calendar-alt-solid.svg';
import calendarCheckIcon from '../../img/calendar-check-solid.svg';
import calendarMinusIcon from '../../img/calendar-minus-solid.svg';
import calendarTimesIcon from '../../img/calendar-times-solid.svg';
import userIcon from '../../img/user.svg';
import userMinusIcon from '../../img/user-minus-solid.svg';
import userTimesIcon from '../../img/user-times-solid.svg';
import userCheckIcon from '../../img/user-check-solid.svg';
import foldersIcons from '../../img/folders-r.svg';
import generalZonaIcon from '../../img/general-zona.svg';
import dinamicaGastos from '../../img/dinamica-gastos.svg';
import reporteVolumen from '../../img/reporte-volumen.svg';

type Props = {
  icon: string;
  imageStyle?: CSSProperties;
  className?: string;
};

const GenericIcons = (props: Props) => {
  const { icon, imageStyle } = props;

  const iconData = [
    {
      icon: 'update',
      svg: update
    },
    {
      icon: 'datafy',
      svg: datafy
    },
    {
      icon: 'logout',
      svg: logout
    },
    {
      icon: 'mantainer',
      svg: manteiner
    },
    {
      icon: 'count',
      svg: count
    },
    {
      icon: 'arrow',
      svg: arrow
    },
    {
      icon: 'avatar',
      svg: avatar
    },
    {
      icon: 'edit',
      svg: edit
    },
    {
      icon: 'filter',
      svg: filter
    },
    {
      icon: 'add',
      svg: add
    },
    {
      icon: 'arrowLeft',
      svg: arrowLeft
    },
    {
      icon: 'info',
      svg: info
    },
    {
      icon: 'infoWhite',
      svg: infoWhite
    },
    {
      icon: 'report',
      svg: report
    },
    {
      icon: 'table',
      svg: table
    },
    {
      icon: 'trash',
      svg: trash
    },
    {
      icon: 'eye',
      svg: Eye
    },
    {
      icon: 'duplicate',
      svg: duplicate
    },
    {
      icon: 'trashWhite',
      svg: trashWhite
    },
    {
      icon: 'duplicateWhite',
      svg: duplicateWhite
    },
    {
      icon: 'CaretRightIcon',
      svg: CaretRightIcon
    },
    {
      icon: 'HouseIcon',
      svg: HouseIcon
    },
    {
      icon: 'FilterIcon',
      svg: FilterIcon
    },
    {
      icon: 'pageNotFound',
      svg: pageNotFound
    },
    {
      icon: 'truckIcon',
      svg: truckIcon
    },
    {
      icon: 'truckIcon2',
      svg: truckIcon2
    },
    {
      icon: 'truckIcon2',
      svg: truckIcon2
    },
    {
      icon: 'calendarIcon',
      svg: calendarIcon
    },
    {
      icon: 'manageMoneyIcon',
      svg: manageMoneyIcon
    },
    {
      icon: 'checkingBoxesIcon',
      svg: checkingBoxesIcon
    },
    {
      icon: 'coinsIcon',
      svg: coinsIcon
    },
    {
      icon: 'acarreoIcon',
      svg: acarreoIcon
    },
    {
      icon: 'moneyStressIcon',
      svg: moneyStressIcon
    },
    {
      icon: 'clipboardTextIcon',
      svg: clipboardTextIcon
    },
    {
      icon: 'signInIcon',
      svg: signInIcon
    },
    {
      icon: 'packageIcon',
      svg: packageIcon
    },
    {
      icon: 'moneyIcon',
      svg: moneyIcon
    },
    {
      icon: 'dollyIcon',
      svg: dollyIcon
    },
    {
      icon: 'truckRIcon',
      svg: truckRIcon
    },
    {
      icon: 'porteoIcon',
      svg: porteoIcon
    },
    {
      icon: 'mathOperationsIcon',
      svg: mathOperationsIcon
    },
    {
      icon: 'packageBlueIcon',
      svg: packageBlueIcon
    },
    {
      icon: 'piggyBankIcon',
      svg: piggyBankIcon
    },
    {
      icon: 'dollyGreenIcon',
      svg: dollyGreenIcon
    },
    {
      icon: 'cashIcon',
      svg: cashIcon
    },
    {
      icon: 'paymentIcon',
      svg: paymentIcon
    },
    {
      icon: 'calculatorIcon',
      svg: calculatorIcon
    },
    {
      icon: 'chalkboardIcon',
      svg: chalkboardIcon
    },
    {
      icon: 'listChecksIcon',
      svg: listChecksIcon
    },
    {
      icon: 'receiptIcon',
      svg: receiptIcon
    },
    {
      icon: 'warningCircleIcon',
      svg: warningCircleIcon
    },
    {
      icon: 'xCircleIcon',
      svg: xCircleIcon
    },
    {
      icon: 'provisionIcon',
      svg: provisionIcon
    },
    {
      icon: 'reclasificacionIcon',
      svg: reclasificacionIcon
    },
    {
      icon: 'calendarAltIcon',
      svg: calendarAltIcon
    },
    {
      icon: 'calendarCheckIcon',
      svg: calendarCheckIcon
    },
    {
      icon: 'calendarMinusIcon',
      svg: calendarMinusIcon
    },
    {
      icon: 'calendarTimesIcon',
      svg: calendarTimesIcon
    },
    {
      icon: 'userIcon',
      svg: userIcon
    },
    {
      icon: 'userCheckIcon',
      svg: userCheckIcon
    },
    {
      icon: 'userMinusIcon',
      svg: userMinusIcon
    },
    {
      icon: 'userTimesIcon',
      svg: userTimesIcon
    },
    {
      icon: 'foldersIcons',
      svg: foldersIcons
    },
    {
      icon: 'generalZonaIcon',
      svg: generalZonaIcon
    },
    {
      icon: 'dinamicaGastos',
      svg: dinamicaGastos
    },
    {
      icon: 'reporteVolumen',
      svg: reporteVolumen
    }
  ];

  const findIcon = (icon_: string) => {
    const icon = iconData.find((p) => p.icon === icon_);

    return icon ? icon?.svg : null;
  };

  const SVGIcon = icon ? findIcon(icon) : null;

  return SVGIcon ? (
    <img className={props.className} src={SVGIcon} alt='' style={{ ...imageStyle }} />
  ) : null;
};

export default GenericIcons;
