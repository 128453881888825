import { FreightProvision } from '../types';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import moment from 'moment';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

const FreightProvisionEntity: WebEntity<FreightProvision> = {
  name: 'freightProvision',
  endpoint: 'freightProvision',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Provision'
  },
  customDataActions: {
    addModalTitle: 'Agregar Provision',
    editModalTitle: 'Editar Provision'
  },
  tableColumns: [
    {
      header: 'Mes',
      accessorKey: 'freightProvisionMonth',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row.original?.freightProvisionMonth
          ? `${
              spanishMonthNames[new Date(row.original?.freightProvisionMonth).getUTCMonth()]
            } ${new Date(row.original?.freightProvisionMonth).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Monto',
      accessorKey: 'freightProvisionAmount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.freightProvisionAmount
          ? formatStringNumber(row?.original?.freightProvisionAmount, 0, 2)
          : ''
    }
  ],

  fields: [
    {
      name: 'Mes',
      selector: 'freightProvisionMonth',
      type: FieldTypes.Custom,
      required: true,
      CustomField: (onChange, value: any, _) => {
        const TimeForm: React.FC = () => {
          const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
            value ? moment(new Date(dateFormatFn(value, 'MM-DD-YYYY', true))) : null
          );

          return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                onMonthChange={(date) => onChange(new Date(date.format('MM-DD-YYYY')))}
              />
            </LocalizationProvider>
          );
        };
        return (
          <div style={{ display: 'flex' }}>
            <TimeForm />
          </div>
        );
      }
    },
    {
      name: 'Monto',
      selector: 'freightProvisionAmount',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Monto',
      selector: 'freightProvisionAmount',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default FreightProvisionEntity;
