import React, { FunctionComponent } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ExportContainer: FunctionComponent<{
  containerId: string;
  fileName: string;
  disabled: boolean;
  callbackBefore?: () => void;
  callbackAfter?: () => void;
}> = ({ containerId, fileName, disabled, callbackBefore, callbackAfter }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportToImage = async () => {
    handleClose();
    setLoading(true);

    try {
      if (callbackBefore) {
        callbackBefore();
      }
      const container = document.getElementById(containerId);
      if (!container) {
        throw Error('Container not found');
      }

      const canvas = await html2canvas(container);
      const dataURL = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `${fileName}.png`;
      link.click();
    } catch (error) {
      console.error('Error exporting chart to image:', error);
    } finally {
      setLoading(false);
      if (callbackAfter) {
        callbackAfter();
      }
    }
  };

  const exportToPDF = async () => {
    handleClose();
    setLoading(true);

    try {
      if (callbackBefore) {
        callbackBefore();
      }
      const container = document.getElementById(containerId);
      if (!container) {
        throw Error('Container not found');
      }

      const canvas = await html2canvas(container);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      console.error('Error exporting chart to PDF:', error);
    } finally {
      setLoading(false);
      if (callbackAfter) {
        callbackAfter();
      }
    }
  };

  return (
    <>
      <Button variant='contained' onClick={handleClick} disabled={loading || disabled}>
        <FileDownloadIcon /> Exportar gráfico
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={exportToImage} disabled={disabled}>
          <ImageIcon /> Imagen
        </MenuItem>
        <MenuItem onClick={exportToPDF} disabled={disabled}>
          <PictureAsPdfIcon /> PDF
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportContainer;
