import { Fragment, FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Stack, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BackButton from '../components/BackButton';
import { connect, ConnectedComponent, useDispatch } from 'react-redux';
import { columns as reporteVolumenColumns } from '../entities/ReporteVolumenEntity';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { getUncatchEndpointData } from '../utils/request';
import { ReportePieChart, ReporteVolumen as ReporteVolumenType, SimpleObject } from '../types';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { ExtendedColumnDef, WebEntity } from '../entities/types';
import { snakeToCamelCase } from '../utils/convert';
import {
  ReporteVolumenCRUD,
  VolumenPieChartComparisonCRUD,
  VolumenPieChartCRUD
} from '../components/GeneratedComponents';
import * as E from '../entities';
import { loadEntityDataAction } from '../actions/entitiesActions';
import ExportContainer from '../components/ExportContainer';
import {
  BarPlot,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  PieChart,
  ResponsiveChartContainer
} from '@mui/x-charts';
import '../css/reporteVolumen.css';

interface DropdownProps extends DropdownItemProps {
  value: string | number;
  text: string;
}

const ReporteVolumenComponent: FunctionComponent<{
  entities: EntitiesState;
}> = ({ entities }) => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='reporte-volumen-container'>
      <div>
        <BackButton route='/reporteria' />
      </div>

      <h2 className='reporte-volumen-title'>Reporte de Volúmen</h2>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Volumen' value='1' />
            <Tab label='Grafico Torta' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <VolumeComponent entities={entities} />
        </TabPanel>
        <TabPanel value='2'>
          <PieChartComponent entities={entities} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const ReporteVolumen = connect(({ entities }: AppState) => ({
  entities
}))(ReporteVolumenComponent);

const VolumeComponent: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [columnasList, setColumnasList] = useState<Array<DropdownProps>>([]);
  const [indicatorsList, setIndicatorsList] = useState<Array<DropdownProps>>([]);
  const [mesList, setMesList] = useState<Array<DropdownProps>>([]);
  const [groupVolumeList, setGroupVolumeList] = useState<Array<DropdownProps>>([]);
  const [selectedColumnas, setSelectedColumnas] = useState<Array<DropdownProps>>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<Array<DropdownProps>>([]);
  const [selectedMes, setSelectedMes] = useState<Array<DropdownProps>>([]);
  const [selectedGroupVolume, setSelectedGroupVolume] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    setLoading(true);

    const colums = reporteVolumenColumns.map((obj, idx) => ({
      key: `col_${idx}`,
      value: obj.accessorKey as string,
      text: obj.header
    }));
    setColumnasList(colums);
    setSelectedColumnas(colums);

    (async () => {
      const indicators = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/indicadores'
      });
      if (indicators && indicators.length > 0) {
        const data: DropdownProps[] = indicators.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.columnName,
          text: row.indicador
        }));
        setIndicatorsList(data);
        const currentYear = new Date().getFullYear();
        const inititalIndicators = data.filter((d) => d.text.includes(currentYear.toString()));
        setSelectedIndicators(inititalIndicators);
      }
    })();

    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/groupVolume'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `groupVolume_${idx}`,
          value: row.groupVolumeId,
          text: row.groupVolumeName
        }));
        setGroupVolumeList(data);
      }
    })();

    setMesList(
      spanishMonthNames.map((monthName, idx) => ({
        key: `mes_${idx}`,
        value: idx,
        text: monthName
      }))
    );
    const currentMonth = new Date().getMonth();
    const findCurrentMonth = spanishMonthNames[currentMonth];
    setSelectedMes(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );

    setLoading(false);
  }, []);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns = reporteVolumenColumns.filter((c) =>
      selectedColumnas.find((s) => s.value === c.accessorKey)
    );

    selectedIndicators.forEach((i) => {
      const key = (i.value || '').toString();
      const accessorKey = snakeToCamelCase(key);
      tableColumns.push({
        header: i.text,
        accessorKey,
        columnType: 'numeric',
        accessorFn: (row) => row[accessorKey],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[accessorKey])}
          </div>
        ),
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => {
          const sum = cell.getValue();
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
            </div>
          );
        },
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) =>
              obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    });

    const entity: WebEntity<ReporteVolumenType> = { ...E.ReporteVolumenEntity, tableColumns };

    const columns = selectedColumnas.map((s) => s.value);
    const indicators = selectedIndicators.map((i) => i.value);
    const months = selectedMes.map((i) => i.value);
    const groups = selectedGroupVolume.map((i) => i.value);

    return ReporteVolumenCRUD(entity, {
      columns,
      indicators,
      months,
      groups
    });
  }, [selectedColumnas, selectedIndicators, selectedMes, selectedGroupVolume]);

  return (
    <Fragment>
      <div className='reporte-volumen-collapse-filters-container'>
        <div className='reporte-volumen-filters-section'>
          <h2 className='reporte-volumen-filters-subtitle'>Columnas</h2>
          <div className='reporte-volumen-filters-container'>
            <Dropdown
              options={columnasList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = columnasList.filter((item) => value.includes(item.value));
                setSelectedColumnas(updatedSelected);
              }}
              value={selectedColumnas.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Columnas'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />
            <Dropdown
              options={indicatorsList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = indicatorsList.filter((item) => value.includes(item.value));
                setSelectedIndicators(updatedSelected);
              }}
              value={selectedIndicators.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Indicadores'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />
          </div>
          <h2 className='reporte-volumen-filters-subtitle'>Filtros</h2>
          <div className='reporte-volumen-filters-container'>
            <Dropdown
              options={mesList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = mesList.filter((item) => value.includes(item.value));
                setSelectedMes(updatedSelected);
              }}
              value={selectedMes.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Mes'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Dropdown
              options={groupVolumeList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = groupVolumeList.filter((item) =>
                  value.includes(item.value)
                );
                setSelectedGroupVolume(updatedSelected);
              }}
              value={selectedGroupVolume.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Grupo'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />
          </div>
        </div>
      </div>

      <CRUD />
    </Fragment>
  );
};

const PieChartComponent: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pieChartValues: Array<DropdownProps> = [
    { key: 'categoriesName_1', value: 'zoneGlobalName', text: 'Zona Global' },
    { key: 'categoriesName_2', value: 'zoneVolumeName', text: 'Zona' },
    { key: 'categoriesName_3', value: 'distributionCenterVolumeName', text: 'CD' },
    { key: 'categoriesName_4', value: 'groupVolumeName', text: 'Grupo' },
    { key: 'categoriesName_5', value: 'monthDate', text: 'Mes' }
  ];
  const [indicatorsList, setIndicatorsList] = useState<Array<DropdownProps>>([]);
  const [zoneGlobalList, setZoneGlobalList] = useState<Array<DropdownProps>>([]);
  const [zoneVolumeList, setZoneVolumeList] = useState<Array<DropdownProps>>([]);
  const [distributionCenterVolumeList, setDistributionCenterVolumeList] = useState<
    Array<DropdownProps>
  >([]);
  const [groupVolumeList, setGroupVolumeList] = useState<Array<DropdownProps>>([]);
  const [mesList, setMesList] = useState<Array<DropdownProps>>([]);

  const [selectedPieChartValue_1, setSelectedPieChartValue_1] = useState<DropdownProps | undefined>(
    pieChartValues[0]
  );
  const [selectedPieChartValue_2, setSelectedPieChartValue_2] = useState<DropdownProps | undefined>(
    pieChartValues[0]
  );
  const [selectedIndicator_1, setSelectedIndicator_1] = useState<DropdownProps | undefined>(
    undefined
  );
  const [selectedIndicator_2, setSelectedIndicator_2] = useState<DropdownProps | undefined>(
    undefined
  );
  const [selectedZoneGlobal_1, setSelectedZoneGlobal_1] = useState<Array<DropdownProps>>([]);
  const [selectedZoneGlobal_2, setSelectedZoneGlobal_2] = useState<Array<DropdownProps>>([]);
  const [selectedZoneVolume_1, setSelectedZoneVolume_1] = useState<Array<DropdownProps>>([]);
  const [selectedZoneVolume_2, setSelectedZoneVolume_2] = useState<Array<DropdownProps>>([]);
  const [selectedDistributionCenterVolume_1, setSelectedDistributionCenterVolume_1] = useState<
    Array<DropdownProps>
  >([]);
  const [selectedDistributionCenterVolume_2, setSelectedDistributionCenterVolume_2] = useState<
    Array<DropdownProps>
  >([]);
  const [selectedGroupVolume_1, setSelectedGroupVolume_1] = useState<Array<DropdownProps>>([]);
  const [selectedGroupVolume_2, setSelectedGroupVolume_2] = useState<Array<DropdownProps>>([]);
  const [selectedMes_1, setSelectedMes_1] = useState<Array<DropdownProps>>([]);
  const [selectedMes_2, setSelectedMes_2] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const indicators = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/indicadores'
      });

      if (indicators && indicators.length > 0) {
        const data: DropdownProps[] = indicators.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.columnName,
          text: row.indicador
        }));
        setIndicatorsList(data);
        const currentYear = new Date().getFullYear();
        setSelectedIndicator_1(data.find((d) => d.text.includes(`${currentYear.toString()}R`)));
        setSelectedIndicator_2(data.find((d) => d.text.includes(`${currentYear.toString()}P`)));
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/zoneGlobalVolume'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `zoneGlobal_${idx}`,
          value: row.zoneGlobalId,
          text: row.zoneGlobalName
        }));
        setZoneGlobalList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/zoneVolume'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `zoneVolume_${idx}`,
          value: row.zoneVolumeId,
          text: row.zoneVolumeName
        }));
        setZoneVolumeList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/distributionCenterVolume'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `distributionCenterVolume_${idx}`,
          value: row.distributionCenterVolumeId,
          text: row.distributionCenterVolumeName
        }));
        setDistributionCenterVolumeList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/volumen/groupVolume'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `groupVolume_${idx}`,
          value: row.groupVolumeId,
          text: row.groupVolumeName
        }));
        setGroupVolumeList(data);
      }
    })();

    setMesList(
      spanishMonthNames.map((monthName, idx) => ({
        key: `mes_${idx}`,
        value: idx,
        text: monthName
      }))
    );
    const currentMonth = new Date().getMonth();
    const findCurrentMonth = spanishMonthNames[currentMonth];
    setSelectedMes_1(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );
    setSelectedMes_2(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );
    setLoading(false);
  }, []);

  const CRUD1: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [];

    if (selectedPieChartValue_1) {
      const accessorKey = selectedPieChartValue_1.value as string;
      if (accessorKey === 'monthDate') {
        tableColumns.push({
          header: selectedPieChartValue_1.text,
          accessorKey,
          columnType: 'text',
          valueToExport: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          },
          accessorFn: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          }
        });
      } else {
        tableColumns.push({
          header: selectedPieChartValue_1.text,
          accessorKey,
          columnType: 'text'
        });
      }
    }

    if (selectedIndicator_1) {
      const key = (selectedIndicator_1.value || '').toString();
      const accessorKey = snakeToCamelCase(key);
      tableColumns.push({
        header: selectedIndicator_1.text,
        accessorKey,
        columnType: 'numeric',
        accessorFn: (row) => row[accessorKey],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[accessorKey])}
          </div>
        ),
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) =>
              obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    }

    const entity: WebEntity<ReportePieChart> = { ...E.VolumenPieChart1Entity, tableColumns };

    const column = selectedPieChartValue_1?.value;
    const indicator = selectedIndicator_1?.value;
    const months = selectedMes_1.map((i) => i.value);

    const zoneGlobals = selectedZoneGlobal_1.map((i) => i.value);
    const zones = selectedZoneVolume_1.map((i) => i.value);
    const cds = selectedDistributionCenterVolume_1.map((i) => i.value);
    const groups = selectedGroupVolume_1.map((i) => i.value);

    return VolumenPieChartCRUD(entity, {
      column,
      indicator,
      months,
      zoneGlobals,
      zones,
      cds,
      groups
    });
  }, [
    selectedIndicator_1,
    selectedPieChartValue_1,
    selectedMes_1,
    selectedZoneGlobal_1,
    selectedZoneVolume_1,
    selectedDistributionCenterVolume_1,
    selectedGroupVolume_1
  ]);

  const CRUD2: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [];

    if (selectedPieChartValue_2) {
      const accessorKey = selectedPieChartValue_2.value as string;
      if (accessorKey === 'monthDate') {
        tableColumns.push({
          header: selectedPieChartValue_2.text,
          accessorKey,
          columnType: 'text',
          valueToExport: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          },
          accessorFn: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          }
        });
      } else {
        tableColumns.push({
          header: selectedPieChartValue_2.text,
          accessorKey,
          columnType: 'text'
        });
      }
    }

    if (selectedIndicator_2) {
      const key = (selectedIndicator_2.value || '').toString();
      const accessorKey = snakeToCamelCase(key);
      tableColumns.push({
        header: selectedIndicator_2.text,
        accessorKey,
        columnType: 'numeric',
        accessorFn: (row) => row[accessorKey],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[accessorKey])}
          </div>
        ),
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) =>
              obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    }

    const entity: WebEntity<ReportePieChart> = { ...E.VolumenPieChart2Entity, tableColumns };

    const column = selectedPieChartValue_2?.value;
    const indicator = selectedIndicator_2?.value;
    const months = selectedMes_2.map((i) => i.value);

    const zoneGlobals = selectedZoneGlobal_2.map((i) => i.value);
    const zones = selectedZoneVolume_2.map((i) => i.value);
    const cds = selectedDistributionCenterVolume_2.map((i) => i.value);
    const groups = selectedGroupVolume_2.map((i) => i.value);

    return VolumenPieChartCRUD(entity, {
      column,
      indicator,
      months,
      zoneGlobals,
      zones,
      cds,
      groups
    });
  }, [
    selectedIndicator_2,
    selectedPieChartValue_2,
    selectedMes_2,
    selectedZoneGlobal_2,
    selectedZoneVolume_2,
    selectedDistributionCenterVolume_2,
    selectedGroupVolume_2
  ]);

  const [pieChartData_1, setPieChartData_1] = useState<SimpleObject[]>([]);
  const [absTotalPieChartData_1, setAbsTotalPieChartData_1] = useState<number>(0);
  const [pieChartData_2, setPieChartData_2] = useState<SimpleObject[]>([]);
  const [absTotalPieChartData_2, setAbsTotalPieChartData_2] = useState<number>(0);

  useEffect(() => {
    const data = entities.volumenPieChart1.list;
    const serie = selectedPieChartValue_1?.value as string;
    const value = snakeToCamelCase((selectedIndicator_1?.value || '').toString());

    setAbsTotalPieChartData_1(data.reduce((sum, row) => sum + Math.abs(Number(row[value])), 0));

    const processedData: SimpleObject[] = data.map((row, idx) => {
      if (
        serie === 'monthDate' &&
        !isNaN(new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getTime())
      ) {
        const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
        const month = date.getMonth();
        return {
          id: idx,
          value: Math.abs(Number(row[value])),
          label: spanishMonthNames[month],
          originalValue: Number(row[value])
        };
      }
      return {
        id: idx,
        value: Math.abs(Number(row[value])),
        label: row[serie],
        originalValue: Number(row[value])
      };
    });
    setPieChartData_1(processedData);
  }, [entities.volumenPieChart1.list, selectedPieChartValue_1?.value, selectedIndicator_1?.value]);

  useEffect(() => {
    const data = entities.volumenPieChart2.list;
    const serie = selectedPieChartValue_2?.value as string;
    const value = snakeToCamelCase((selectedIndicator_2?.value || '').toString());

    setAbsTotalPieChartData_2(data.reduce((sum, row) => sum + Math.abs(Number(row[value])), 0));

    const processedData: SimpleObject[] = data.map((row, idx) => {
      if (
        serie === 'monthDate' &&
        !isNaN(new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getTime())
      ) {
        const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
        const month = date.getMonth();
        return {
          id: idx,
          value: Math.abs(Number(row[value])),
          label: spanishMonthNames[month],
          originalValue: Number(row[value])
        };
      }

      return {
        id: idx,
        value: Math.abs(Number(row[value])),
        label: row[serie],
        originalValue: Number(row[value])
      };
    });

    setPieChartData_2(processedData);
  }, [entities.volumenPieChart2.list, selectedPieChartValue_2?.value, selectedIndicator_2?.value]);

  const CRUDcomparison: ConnectedComponent<any, any> | null = useMemo(() => {
    const CRUD_1List = entities.volumenPieChart1.list;
    const value_1 = selectedPieChartValue_1;
    const serie_1 = selectedIndicator_1;
    const CRUD_2List = entities.volumenPieChart2.list;
    const value_2 = selectedPieChartValue_2;
    const serie_2 = selectedIndicator_2;

    if (
      CRUD_1List.length === 0 ||
      CRUD_2List.length === 0 ||
      !value_1 ||
      !serie_1 ||
      !value_2 ||
      !serie_2 ||
      value_1?.value !== value_2?.value
    )
      return null;

    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [
      {
        header: value_1.text,
        accessorKey: value_1.value.toString(),
        columnType: 'text'
      },
      {
        header: serie_1.text,
        accessorKey: serie_1.value.toString(),
        columnType: 'numeric',
        accessorFn: (row) => row[serie_1.value.toString()],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[serie_1.value.toString()])}
          </div>
        )
      },
      {
        header: serie_2.text,
        accessorKey: serie_2.value.toString(),
        columnType: 'numeric',
        accessorFn: (row) => row[serie_2.value.toString()],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[serie_2.value.toString()])}
          </div>
        )
      },
      {
        header: 'Variación',
        accessorKey: 'comparison',
        columnType: 'percentage',
        accessorFn: (row) => row.comparison,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber((Number(row.original.comparison) * 100).toString(), 0, 2)} %
          </div>
        )
      }
    ];

    const entity: WebEntity<ReportePieChart> = {
      ...E.VolumenPieCharComparisonEntity,
      tableColumns
    };
    return VolumenPieChartComparisonCRUD(entity);
  }, [
    entities.volumenPieChart1.list,
    selectedPieChartValue_1,
    selectedIndicator_1,
    entities.volumenPieChart2.list,
    selectedPieChartValue_2,
    selectedIndicator_2
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const value_1 = selectedPieChartValue_1;
    const serie_1 = selectedIndicator_1;
    const value_2 = selectedPieChartValue_2;
    const serie_2 = selectedIndicator_2;

    if (
      entities.volumenPieChart1.list.length > 0 &&
      entities.volumenPieChart2.list.length > 0 &&
      value_1 &&
      serie_1 &&
      value_2 &&
      serie_2 &&
      value_1?.value === value_2?.value
    ) {
      const CRUD_1List = entities.volumenPieChart1.list.map((row) => {
        if (value_1.value === 'monthDate') {
          const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
          if (isNaN(date.getTime())) {
            return row;
          }
          const month = date.getMonth();
          return { ...row, monthDate: spanishMonthNames[month] };
        }
        return row;
      });

      const CRUD_2List = entities.volumenPieChart2.list.map((row) => {
        if (value_2.value === 'monthDate') {
          const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
          if (isNaN(date.getTime())) {
            return row;
          }
          const month = date.getMonth();
          return { ...row, monthDate: spanishMonthNames[month] };
        }
        return row;
      });

      const allValues = Array.from(
        new Set([
          ...CRUD_1List.map((row) => row[value_1.value]),
          ...CRUD_2List.map((row) => row[value_1.value])
        ])
      ).sort((a, b) => {
        const indexA =
          value_1.value === 'monthDate' ? spanishMonthNames.findIndex((m) => m === a) : a;
        const indexB =
          value_1.value === 'monthDate' ? spanishMonthNames.findIndex((m) => m === b) : b;

        if (indexA > indexB) {
          return 1;
        }
        if (indexA < indexB) {
          return -1;
        }

        return 0;
      });

      const data = allValues.map((value) => {
        const find_1 = CRUD_1List.find((row) => row[value_1.value] === value);
        const find_2 = CRUD_2List.find((row) => row[value_2.value] === value);

        const serie_1Value = find_1
          ? Number(find_1[snakeToCamelCase((serie_1.value || '').toString())])
          : 0;
        const serie_2Value = find_2
          ? Number(find_2[snakeToCamelCase((serie_2.value || '').toString())])
          : 0;

        return {
          [value_1.value]: value,
          [serie_1.value]: serie_1Value,
          [serie_2.value]: serie_2Value,
          comparison: !serie_2Value ? 0 : (serie_1Value / serie_2Value - 1) * -1
        };
      });

      dispatch(loadEntityDataAction(E.VolumenPieCharComparisonEntity.name)(data as any));
    }
  }, [
    dispatch,
    entities.volumenPieChart1.list,
    selectedPieChartValue_1,
    selectedIndicator_1,
    entities.volumenPieChart2.list,
    selectedPieChartValue_2,
    selectedIndicator_2
  ]);

  return (
    <div className='reporte-volumen-pie-chart-page-container'>
      <div className='reporte-volumen-pie-chart-section'>
        <div className='reporte-volumen-pie-chart-container'>
          <Dropdown
            options={pieChartValues}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = pieChartValues.find((item) => item.value === value);
              setSelectedPieChartValue_1(updatedSelected);
            }}
            value={selectedPieChartValue_1 ? selectedPieChartValue_1.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Valor'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={indicatorsList}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = indicatorsList.find((item) => item.value === value);
              setSelectedIndicator_1(updatedSelected);
            }}
            value={selectedIndicator_1 ? selectedIndicator_1.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Serie'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={zoneGlobalList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zoneGlobalList.filter((item) => value.includes(item.value));
              setSelectedZoneGlobal_1(updatedSelected);
            }}
            value={selectedZoneGlobal_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona Global'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={zoneVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zoneVolumeList.filter((item) => value.includes(item.value));
              setSelectedZoneVolume_1(updatedSelected);
            }}
            value={selectedZoneVolume_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={distributionCenterVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = distributionCenterVolumeList.filter((item) =>
                value.includes(item.value)
              );
              setSelectedDistributionCenterVolume_1(updatedSelected);
            }}
            value={selectedDistributionCenterVolume_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='CD'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={groupVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = groupVolumeList.filter((item) => value.includes(item.value));
              setSelectedGroupVolume_1(updatedSelected);
            }}
            value={selectedGroupVolume_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Grupo'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={mesList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = mesList.filter((item) => value.includes(item.value));
              setSelectedMes_1(updatedSelected);
            }}
            value={selectedMes_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Mes'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />

          <CRUD1 />

          <div className='reporte-volumen-export-btn-container'>
            <ExportContainer
              containerId='pie-chart-1'
              fileName='grafico-torta-1'
              disabled={pieChartData_1.length === 0}
              callbackBefore={() => {
                const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'block';
                });
              }}
              callbackAfter={() => {
                const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'none';
                });
              }}
            />
          </div>

          <div id='pie-chart-1' className='reporte-volumen-pie-chart-wrapper'>
            <h2 className='reporte-volumen-chart-title'>Volumen</h2>
            <PieChart
              loading={loading}
              series={[
                {
                  data: pieChartData_1.map((obj) => ({
                    id: obj.id,
                    value: obj.value,
                    label: obj.label
                  })),
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  valueFormatter: (_, { dataIndex }) => {
                    const { originalValue } = pieChartData_1[dataIndex];
                    const formattedOriginalValue = formatStringNumber(originalValue);
                    const percentage = !absTotalPieChartData_1
                      ? 0
                      : (originalValue / absTotalPieChartData_1) * 100;
                    const formattedPercentage = formatStringNumber(percentage.toString(), 0, 2);
                    return `${formattedOriginalValue} (${formattedPercentage}%)`;
                  }
                }
              ]}
              height={pieChartData_1.length > 16 ? 400 + (pieChartData_1.length - 16) * 24 : 400}
              margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                  itemMarkWidth: 10,
                  itemMarkHeight: 9,
                  markGap: 5,
                  itemGap: 10
                }
              }}
            />
          </div>
        </div>

        <div className='reporte-volumen-pie-chart-container'>
          <Dropdown
            options={pieChartValues}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = pieChartValues.find((item) => item.value === value);
              setSelectedPieChartValue_2(updatedSelected);
            }}
            value={selectedPieChartValue_2 ? selectedPieChartValue_2.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Valor'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={indicatorsList}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = indicatorsList.find((item) => item.value === value);
              setSelectedIndicator_2(updatedSelected);
            }}
            value={selectedIndicator_2 ? selectedIndicator_2.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Serie'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={zoneGlobalList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zoneGlobalList.filter((item) => value.includes(item.value));
              setSelectedZoneGlobal_2(updatedSelected);
            }}
            value={selectedZoneGlobal_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona Global'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={zoneVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zoneVolumeList.filter((item) => value.includes(item.value));
              setSelectedZoneVolume_2(updatedSelected);
            }}
            value={selectedZoneVolume_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={distributionCenterVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = distributionCenterVolumeList.filter((item) =>
                value.includes(item.value)
              );
              setSelectedDistributionCenterVolume_2(updatedSelected);
            }}
            value={selectedDistributionCenterVolume_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='CD'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={groupVolumeList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = groupVolumeList.filter((item) => value.includes(item.value));
              setSelectedGroupVolume_2(updatedSelected);
            }}
            value={selectedGroupVolume_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Grupo'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={mesList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = mesList.filter((item) => value.includes(item.value));
              setSelectedMes_2(updatedSelected);
            }}
            value={selectedMes_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Mes'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />

          <CRUD2 />

          <div className='reporte-volumen-export-btn-container'>
            <ExportContainer
              containerId='pie-chart-2'
              fileName='grafico-torta-2'
              disabled={pieChartData_2.length === 0}
              callbackBefore={() => {
                const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'block';
                });
              }}
              callbackAfter={() => {
                const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'none';
                });
              }}
            />
          </div>

          <div id='pie-chart-2' className='reporte-volumen-pie-chart-wrapper'>
            <h2 className='reporte-volumen-chart-title'>Volumen</h2>
            <PieChart
              loading={loading}
              series={[
                {
                  data: pieChartData_2.map((obj) => ({
                    id: obj.id,
                    value: obj.value,
                    label: obj.label
                  })),
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  valueFormatter: (_, { dataIndex }) => {
                    const { originalValue } = pieChartData_2[dataIndex];
                    const formattedOriginalValue = formatStringNumber(originalValue);
                    const percentage = !absTotalPieChartData_2
                      ? 0
                      : (originalValue / absTotalPieChartData_2) * 100;
                    const formattedPercentage = formatStringNumber(percentage.toString(), 0, 2);
                    return `${formattedOriginalValue} (${formattedPercentage}%)`;
                  }
                }
              ]}
              height={pieChartData_2.length > 16 ? 400 + (pieChartData_2.length - 16) * 24 : 400}
              margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                  itemMarkWidth: 10,
                  itemMarkHeight: 9,
                  markGap: 5,
                  itemGap: 10
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='reporte-volumen-pie-chart-container'>
        {!CRUDcomparison ? (
          <Alert severity='warning'>No es posible realizar una comparación</Alert>
        ) : (
          <>
            <CRUDcomparison />

            <div className='reporte-volumen-export-btn-container'>
              <ExportContainer
                containerId='comparison-chart'
                fileName='variacion'
                disabled={entities.volumenPieChartComparison.list.length === 0}
                callbackBefore={() => {
                  const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                  titles.forEach((title) => {
                    const htmlTittle = title as HTMLElement;
                    htmlTittle.style.display = 'block';
                  });
                }}
                callbackAfter={() => {
                  const titles = document.querySelectorAll('.reporte-volumen-chart-title');
                  titles.forEach((title) => {
                    const htmlTittle = title as HTMLElement;
                    htmlTittle.style.display = 'none';
                  });
                }}
              />
            </div>

            <div id='comparison-chart' className='reporte-volumen-pie-chart-wrapper'>
              <h2 className='reporte-volumen-chart-title'>Volumen</h2>
              <ResponsiveChartContainer
                height={800}
                margin={{ top: 100, bottom: 100, left: 100, right: 50 }}
                dataset={entities.volumenPieChartComparison.list.map((row) => ({
                  ...row,
                  comparison: Number(row.comparison) * 100
                }))}
                series={[
                  {
                    type: 'bar',
                    dataKey: (selectedIndicator_1?.value || '').toString(),
                    label: (selectedIndicator_1?.text || '').toString(),
                    yAxisId: 'leftAxis'
                  },
                  {
                    type: 'bar',
                    dataKey: (selectedIndicator_2?.value || '').toString(),
                    label: (selectedIndicator_2?.text || '').toString(),
                    yAxisId: 'leftAxis'
                  },
                  {
                    type: 'line',
                    dataKey: 'comparison',
                    label: 'Variación (%)',
                    yAxisId: 'rightAxis',
                    valueFormatter: (value) => {
                      return `${formatStringNumber((value || '0').toString(), 0, 2)} %`;
                    }
                  }
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    dataKey: (selectedPieChartValue_1?.value || '').toString(),
                    tickPlacement: 'middle',
                    labelStyle: { fontSize: 14 },
                    tickLabelStyle: {
                      angle: -45,
                      textAnchor: 'end',
                      fontSize: 12
                    },
                    valueFormatter: (value, context) => {
                      if (context.location === 'tick') {
                        if ((value || '').length > 15) {
                          return value.substring(0, 15) + '...';
                        }
                        return value;
                      }
                      return value;
                    }
                  }
                ]}
                yAxis={[
                  {
                    id: 'leftAxis',
                    valueFormatter: (value, context) => {
                      if (context.location === 'tick') {
                        return formatStringNumber(value.toString());
                      }
                      return value;
                    }
                  },
                  {
                    id: 'rightAxis',
                    valueFormatter: (value) => {
                      return `${value} %`;
                    }
                  }
                ]}>
                <BarPlot />
                <LinePlot />
                <ChartsXAxis />
                <ChartsYAxis axisId='leftAxis' position='left' />
                <ChartsYAxis axisId='rightAxis' position='right' />
                <ChartsTooltip />
                <ChartsLegend position={{ horizontal: 'middle', vertical: 'top' }} />
              </ResponsiveChartContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
