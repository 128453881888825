import { MRT_RowData } from 'material-react-table';
import { WebEntityName } from '../entities/types';
import {
  BasicEntity,
  UserType,
  User,
  CostCenter,
  Zone,
  Budget,
  BudgetType,
  Categories,
  DictionaryCc,
  AreaManager,
  ZoneGlobal,
  SubCostCenter,
  Expenses,
  EstimatedClosing,
  PendingEstimatedClose,
  GlobalEstimatedClose,
  GeneralZoneReport,
  Provision,
  Reclassification,
  RefusedEstimatedClose,
  HFM,
  Carrier,
  CarrierType,
  Gloss,
  ZonePorting,
  Concept,
  SubConcept,
  Tariff,
  Volumen,
  Map,
  FreightCharges,
  EquivalentBox,
  ContingencyWithholding,
  FareDifference,
  FareDifferenceSummary,
  EstimatedClosingPorting,
  TimeRoute,
  Ifac,
  ForeignExchange,
  MajorPorting,
  ForeignExchangeEstimated,
  ForeignExchangeResume,
  PermanentTruck,
  CarrierZoneGloss,
  FreightGloss,
  ContractorTariff,
  FreightExtra,
  FreightExtraResume,
  ContingencyWithholdingInconsistencies,
  ManagementType,
  CarrierRouteCarriage,
  CarrierTypeCarriage,
  TariffCarriage,
  FreightProvision,
  FreightExtraGlSap,
  FreightExtraInconsistencies,
  IfacSimulated,
  FreightTimeMaster,
  ExtraCarriage,
  ReportUEN,
  ShipmentCarriage,
  Polynomial,
  ProvisionCarriage,
  BudgetCarriage,
  ShipmentAuditingCarriage,
  ShipmentCarriagePXQ,
  ResumeShipmentCarriage,
  CarriageResume,
  Notification,
  NotificationType,
  ReporteGastos,
  PresupuestoVolumen,
  ReportePieChart,
  ZoneGlobalBudgetVolume,
  ZoneBudgetVolume,
  DistributionCenterBudgetVolume,
  GroupBudgetVolume,
  ReporteVolumen
} from '../types';

/* Auth state */

export type Impersonation = {
  passwordLessToken: string;
  passwordLessUser: User;
};

export type AuthState = {
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  impersonation: Impersonation | null;
};

export const AUTH_ERROR = 'AUTH_ERROR';
export const IMPERSONATE_CLEAR = 'IMPERSONATE_CLEAR';
export const IMPERSONATE_LOADED = 'IMPERSONATE_LOADED';
export const IMPERSONATE_LOADING = 'IMPERSONATE_LOADING';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOADING = 'USER_LOADING';

type AuthErrorAction = {
  type: typeof AUTH_ERROR;
};

type ImpersonateClearAction = {
  type: typeof IMPERSONATE_CLEAR;
};

type ImpersonateLoadedAction = {
  type: typeof IMPERSONATE_LOADED;
  payload: {
    passwordLessToken: string;
    passwordLessUser: User;
  };
};

type ImpersonateLoadingAction = {
  type: typeof IMPERSONATE_LOADING;
};

type LoginFailAction = {
  type: typeof LOGIN_FAIL;
};

type LoginSuccessAction = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    token: string;
    user: User;
  };
};

type LogoutSuccessAction = {
  type: typeof LOGOUT_SUCCESS;
};

type RegisterFailAction = {
  type: typeof REGISTER_FAIL;
};

type RegisterSuccessAction = {
  type: typeof REGISTER_SUCCESS;
  payload: {
    token: string;
    user: User;
  };
};

type UserLoadedAction = {
  type: typeof USER_LOADED;
  payload: User;
};

type UserLoadingAction = {
  type: typeof USER_LOADING;
};

export type AuthActions =
  | AuthErrorAction
  | ImpersonateClearAction
  | ImpersonateLoadedAction
  | ImpersonateLoadingAction
  | LoginFailAction
  | LoginSuccessAction
  | LogoutSuccessAction
  | RegisterFailAction
  | RegisterSuccessAction
  | UserLoadedAction
  | UserLoadingAction;

/* Entity state */

export type SingleEntityState<T extends BasicEntity> = {
  list: T[];
  selected: T[];
  singleSelected: T | null;
  loading: boolean;
  notificationMessage: string;
};

export type EntitiesState = {
  user: SingleEntityState<User>;
  userType: SingleEntityState<UserType>;
  notification: SingleEntityState<Notification>;
  notificationType: SingleEntityState<NotificationType>;
  map: SingleEntityState<Map>;
  costCenter: SingleEntityState<CostCenter>;
  zone: SingleEntityState<Zone>;
  budget: SingleEntityState<Budget>;
  budgetType: SingleEntityState<BudgetType>;
  categories: SingleEntityState<Categories>;
  dictionaryCc: SingleEntityState<DictionaryCc>;
  areaManager: SingleEntityState<AreaManager>;
  zoneGlobal: SingleEntityState<ZoneGlobal>;
  subCostCenter: SingleEntityState<SubCostCenter>;
  expenses: SingleEntityState<Expenses>;
  estimatedClosing: SingleEntityState<EstimatedClosing>;
  pendingBudget: SingleEntityState<Expenses>;
  pendingEstimatedClose: SingleEntityState<PendingEstimatedClose>;
  globalEstimatedClose: SingleEntityState<GlobalEstimatedClose>;
  generalZoneReport: SingleEntityState<GeneralZoneReport>;
  generalReport: SingleEntityState<GeneralZoneReport>;
  provision: SingleEntityState<Provision>;
  reclassification: SingleEntityState<Reclassification>;
  refusedEstimatedClose: SingleEntityState<RefusedEstimatedClose>;
  hfm: SingleEntityState<HFM>;
  carrier: SingleEntityState<Carrier>;
  managementType: SingleEntityState<ManagementType>;
  carrierType: SingleEntityState<CarrierType>;
  gloss: SingleEntityState<Gloss>;
  zonePorting: SingleEntityState<ZonePorting>;
  concept: SingleEntityState<Concept>;
  subConcept: SingleEntityState<SubConcept>;
  tariff: SingleEntityState<Tariff>;
  volumen: SingleEntityState<Volumen>;
  presupuestoVolumen: SingleEntityState<PresupuestoVolumen>;
  zoneGlobalBudgetVolume: SingleEntityState<ZoneGlobalBudgetVolume>;
  zoneBudgetVolume: SingleEntityState<ZoneBudgetVolume>;
  distributionCenterBudgetVolume: SingleEntityState<DistributionCenterBudgetVolume>;
  groupBudgetVolume: SingleEntityState<GroupBudgetVolume>;
  freightChargesQ1: SingleEntityState<FreightCharges>;
  freightChargesQ2: SingleEntityState<FreightCharges>;
  equivalentBox: SingleEntityState<EquivalentBox>;
  majorPorting: SingleEntityState<MajorPorting>;
  contingencyWithholding: SingleEntityState<ContingencyWithholding>;
  contingencyWithholdingInconsistencies: SingleEntityState<ContingencyWithholdingInconsistencies>;
  fareDifference: SingleEntityState<FareDifference>;
  fareDifferenceEntrepreneurs: SingleEntityState<FareDifference>;
  fareDifferenceSummary: SingleEntityState<FareDifferenceSummary>;
  estimatedClosingPorting: SingleEntityState<EstimatedClosingPorting>;
  timeRoute: SingleEntityState<TimeRoute>;
  ifac: SingleEntityState<Ifac>;
  foreignExchange: SingleEntityState<ForeignExchange>;
  foreignExchangeEstimated: SingleEntityState<ForeignExchangeEstimated>;
  foreignExchangeResume: SingleEntityState<ForeignExchangeResume>;
  permanentTruckQ1: SingleEntityState<PermanentTruck>;
  permanentTruckQ2: SingleEntityState<PermanentTruck>;
  carrierZoneGloss: SingleEntityState<CarrierZoneGloss>;
  freightGloss: SingleEntityState<FreightGloss>;
  contractorTariff: SingleEntityState<ContractorTariff>;
  freightExtra: SingleEntityState<FreightExtra>;
  freightExtraResume: SingleEntityState<FreightExtraResume>;
  carrierRouteCarriage: SingleEntityState<CarrierRouteCarriage>;
  carrierTypeCarriage: SingleEntityState<CarrierTypeCarriage>;
  tariffCarriage: SingleEntityState<TariffCarriage>;
  freightProvision: SingleEntityState<FreightProvision>;
  freightExtraGlSap: SingleEntityState<FreightExtraGlSap>;
  freightExtraInconsistencies: SingleEntityState<FreightExtraInconsistencies>;
  ifacSimulated: SingleEntityState<IfacSimulated>;
  freightTimeMaster: SingleEntityState<FreightTimeMaster>;
  extraCarriage: SingleEntityState<ExtraCarriage>;
  reportUEN: SingleEntityState<ReportUEN>;
  shipmentCarriageQ1: SingleEntityState<ShipmentCarriage>;
  shipmentCarriageQ2: SingleEntityState<ShipmentCarriage>;
  shipmentAuditingCarriageQ1: SingleEntityState<ShipmentAuditingCarriage>;
  shipmentAuditingCarriageQ2: SingleEntityState<ShipmentAuditingCarriage>;
  shipmentCarriagePXQQ1: SingleEntityState<ShipmentCarriagePXQ>;
  shipmentCarriagePXQQ2: SingleEntityState<ShipmentCarriagePXQ>;
  resumeShipmentCarriageQ1: SingleEntityState<ResumeShipmentCarriage>;
  resumeShipmentCarriageQ2: SingleEntityState<ResumeShipmentCarriage>;
  polynomial: SingleEntityState<Polynomial>;
  provisionCarriage: SingleEntityState<ProvisionCarriage>;
  budgetCarriageIngresoAcarreo: SingleEntityState<BudgetCarriage>;
  budgetCarriageGastoRepro: SingleEntityState<BudgetCarriage>;
  budgetCarriageGastoAcarreo: SingleEntityState<BudgetCarriage>;
  budgetCarriageVolumenRepro: SingleEntityState<BudgetCarriage>;
  carriageResume: SingleEntityState<CarriageResume>;
  reporteGastos: SingleEntityState<ReporteGastos>;
  gastosPieChart1: SingleEntityState<ReportePieChart>;
  gastosPieChart2: SingleEntityState<ReportePieChart>;
  gastosPieChartComparison: SingleEntityState<ReportePieChart>;
  volumenPieChart1: SingleEntityState<ReportePieChart>;
  volumenPieChart2: SingleEntityState<ReportePieChart>;
  volumenPieChartComparison: SingleEntityState<ReportePieChart>;
  reporteVolumen: SingleEntityState<ReporteVolumen>;
};

export const GET_ENTITY = 'GET_ENTITY';
export const VOID_ENTITY = 'VOID_ENTITY';
export const ADD_ENTITY = 'ADD_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const EDIT_ENTITY = 'EDIT_ENTITY';
export const SELECTED_ENTITY = 'SELECTED_ENTITY';
export const SINGLE_SELECTED_ENTITY = 'SINGLE_SELECTED_ENTITY';
export const LOADING_ENTITY = 'LOADING_ENTITY';
export const NOTIFY_MESSAGE_ENTITY = 'NOTIFY_MESSAGE_ENTITY';
export const REFRESH_ENTITY = 'REFRESH_ENTITY';

// These two are not mapped to any action but rather used within the actions themselves
export const ADD_FAIL_ENTITY = 'ADD_FAIL_ENTITY';
export const DELETE_FAIL_ENTITY = 'DELETE_FAIL_ENTITY';
export const EDIT_FAIL_ENTITY = 'EDIT_FAIL_ENTITY';

type GetEntityAction = {
  type: typeof GET_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type AddEntityAction = {
  type: typeof ADD_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type DeleteEntityAction = {
  type: typeof DELETE_ENTITY;
  payload: string[];
  entityName: WebEntityName;
};

type EditEntityAction = {
  type: typeof EDIT_ENTITY;
  payload: BasicEntity | MRT_RowData;
  entityName: WebEntityName;
};

type SelectedEntityAction = {
  type: typeof SELECTED_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type SingleSelectedEntityAction = {
  type: typeof SINGLE_SELECTED_ENTITY;
  payload: BasicEntity;
  entityName: WebEntityName;
};

type LoadingEntityAction = {
  type: typeof LOADING_ENTITY;
  payload: boolean;
  entityName: WebEntityName;
};

type refreshEntityAction = {
  type: typeof REFRESH_ENTITY;
  payload: null;
  entityName: WebEntityName;
};

type notifyMessageEntityAction = {
  type: typeof NOTIFY_MESSAGE_ENTITY;
  payload: string;
  entityName: WebEntityName;
};

export type EntitiesActions =
  | GetEntityAction
  | AddEntityAction
  | DeleteEntityAction
  | EditEntityAction
  | SelectedEntityAction
  | SingleSelectedEntityAction
  | notifyMessageEntityAction
  | LoadingEntityAction
  | refreshEntityAction;

/* Error state */

export type ErrorState = {
  msg: any;
  status: number | null;
  id: string | null;
};

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

type GetErrorsAction = {
  type: typeof GET_ERRORS;
  payload: {
    msg: any;
    status: number;
    id: string | null;
  };
};

type ClearErrorsAction = {
  type: typeof CLEAR_ERRORS;
};

export type ErrorActions = GetErrorsAction | ClearErrorsAction;
