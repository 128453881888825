import { ZoneBudgetVolume } from '../types';
import { WebEntity } from './types';

const ZoneBudgetVolumeEntity: WebEntity<ZoneBudgetVolume> = {
  name: 'zoneBudgetVolume',
  endpoint: 'volumen/presupuesto/zoneBudgetVolume',
  referenceColumn: 'zoneId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true,
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Zona',
      accessorKey: 'zoneName',
      columnType: 'text'
    }
  ]
};

export default ZoneBudgetVolumeEntity;
