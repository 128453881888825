import { FunctionComponent } from 'react';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/haulingPage.css';

const HaulingPage: FunctionComponent = () => {
  const router = useHistory();
  const buttons: Array<{ name: string; href: string; icon: string }> = [
    { name: 'Ingreso', href: '/mantenedores/acarreo/ifac', icon: 'signInIcon' },
    { name: 'Polinomio Acarreo', href: '/reporte/acarreo/polinomio', icon: 'truckRIcon' },
    { name: 'Flete', href: '/mantenedores/acarreo/flete', icon: 'dollyIcon' },
    { name: 'Extras', href: '/mantenedores/acarreo/extras', icon: 'packageIcon' },
    { name: 'Cobro UEN', href: '/reporte/acarreo/uen', icon: 'moneyIcon' },
    { name: 'Resumen Acarreo', href: '/mantenedores/acarreo/resumen', icon: 'clipboardTextIcon' }
  ];
  return (
    <div className='hauling-page-container'>
      <BackButton route='/modulos' />
      <div className='hauling-page-body'>
        <h2 className='hauling-page-title'>Planificación Acarreo</h2>

        <GenericIcons icon='acarreoIcon' imageStyle={{ height: '55%' }} />

        <div className='hauling-page-buttons-container'>
          {buttons.map((btn, idx) => (
            <div className='hauling-page-button' key={idx} onClick={() => router.push(btn.href)}>
              <GenericIcons icon={btn.icon} /> <p>{btn.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HaulingPage;
