import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Path } from 'history';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/welcomePage.css';

const WelcomePage: FunctionComponent = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const router = useHistory();

  const buttons: Array<{
    name: string;
    img: string;
    href: Path;
    color: string;
  }> = [
    {
      name: 'Volumen',
      img: 'checkingBoxesIcon',
      href: '/volumen',
      color: '#78A434'
    },
    {
      name: 'Gastos',
      img: 'coinsIcon',
      href: '/modulos',
      color: '#497BB5'
    },
    {
      name: 'Reportería Gastos',
      img: 'manageMoneyIcon',
      href: '/reporteria',
      color: '#ECBA48'
    }
  ];

  return (
    <div className='welcome-page-container'>
      {buttons.map((b, idx) => (
        <button
          key={idx}
          className='welcome-button'
          onClick={() => router.push(b.href)}
          onMouseEnter={() => setHoveredIndex(idx)}
          onMouseLeave={() => setHoveredIndex(null)}
          style={{
            borderColor: hoveredIndex === idx ? b.color : '#dfdfdf'
          }}>
          <p>{b.name}</p>
          <GenericIcons icon={b.img} imageStyle={{ maxWidth: '100%' }} />
        </button>
      ))}
    </div>
  );
};

export default WelcomePage;
