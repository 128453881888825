import { GroupBudgetVolume } from '../types';
import { WebEntity } from './types';

const GroupBudgetVolumeEntity: WebEntity<GroupBudgetVolume> = {
  name: 'groupBudgetVolume',
  endpoint: 'volumen/presupuesto/groupBudgetVolume',
  referenceColumn: 'groupId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true,
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Zona Global',
      accessorKey: 'groupName',
      columnType: 'text'
    }
  ]
};

export default GroupBudgetVolumeEntity;
