import { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { connect } from 'react-redux';
import CenterZoneMap from '../components/CenterZoneMap';
import { ModuleOption, UserLevels, UserTypeValues, modules } from '../types';
import GenericInfoTag from '../components/generics/GenericInfoTag';
import { getUncatchEndpointData } from '../utils/request';
import BackButton from '../components/BackButton';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/centerZoneMapPage.css';

interface IProps {
  auth: AuthState;
}

const CenterZoneMapPage: FunctionComponent<IProps> = ({ auth }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [centerAssignment, setCenterAssignment] = useState<
    Array<{
      description: string; //nivel 4 - sub categoria
      zoneDescrp: string; // zona
      zoneGlobalDescrp: string; // zona global
      levelOne: string; // nivel 1
      levelTwo: string; // nivel 2
      levelThree: string; // nivel 3
    }>
  >([]);

  const [cities, setCities] = useState<
    Array<{
      cityName: string;
      cityMatchMap: string;
      zones: Array<string>;
    }>
  >([]);

  useEffect(() => {
    const update = async () => {
      setLoading(true);
      const getCenterAssignment = await getUncatchEndpointData({
        endpoint: 'costCenter/centerAssignment'
      });
      if (getCenterAssignment && getCenterAssignment.length > 0) {
        setCenterAssignment(getCenterAssignment);
      }
      const getCities = await getUncatchEndpointData({ endpoint: 'map/getCitiesForMap' });
      if (getCities && getCities.length > 0) {
        setCities(getCities);
      }
      setLoading(false);
    };
    update();
  }, []);

  const router = useHistory();
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
  }>();

  if (
    !params ||
    !params.optionType ||
    !params.globalZone ||
    !params.level ||
    !params.subCategories
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrió un error al obtener los parametros. Contacta al administrador'
    });
    router.push('/');
  }

  const userTypeAllowed = auth.user?.userTypeName.includes(UserTypeValues.admin);

  const moduleOption = params.optionType as ModuleOption;
  const title = modules[moduleOption].name;

  const optionType = params.optionType;
  const globalZone = params.globalZone;
  const level = params.level;
  const subCategories = params.subCategories.split(',');

  const svgRef = useRef(null);

  const [allowedZones, setAllowedZones] = useState<Array<string>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = () => {
    setAllowedZones([]);
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const onClickFunction = useCallback(
    (cityName: string) => {
      const selectedCity = cities.find((c) => c.cityMatchMap === cityName);
      if (selectedCity) {
        const newAllowedZones: string[] = [];
        selectedCity.zones.forEach((z) => {
          if (userTypeAllowed) {
            newAllowedZones.push(z);
          }
          const matchCenterAssignment = centerAssignment
            .filter((obj) => {
              if (level === UserLevels.levelOne) {
                return obj.levelOne === auth.user?.userUsername;
              } else if (level === UserLevels.levelTwo) {
                return obj.levelTwo === auth.user?.userUsername;
              } else if (level === UserLevels.levelThree) {
                return obj.levelThree === auth.user?.userUsername;
              }
              return obj;
            })
            .find(
              (cA) =>
                cA.zoneGlobalDescrp === globalZone &&
                subCategories.includes(cA.description) &&
                cA.zoneDescrp === z
            );
          if (!userTypeAllowed && matchCenterAssignment) {
            newAllowedZones.push(matchCenterAssignment.zoneDescrp);
          }
        });
        setAllowedZones(newAllowedZones);
      }
      handleShow();
    },
    [cities, centerAssignment, auth, globalZone, level, subCategories, userTypeAllowed]
  );

  const ZoneQuestionModal: FunctionComponent = () => {
    const handleZoneButtonClick = (zone: string) => {
      router.push(`/operaciones/${moduleOption}/${globalZone}/${level}/${subCategories}/${zone}`);
    };
    return (
      <div className='zone-question-modal' style={{ display: showModal ? 'flex' : 'none' }}>
        <div className='zone-question-modal-close-button-container'>
          <button className='zone-question-modal-x-button' onClick={handleClose}>
            x
          </button>
        </div>
        <h2 className='zone-question-modal-title'>¿Con qué Zona deseas Ingresar?</h2>
        <div>
          {allowedZones.map((z, idx) => (
            <p key={idx} className='zone-question-button' onClick={() => handleZoneButtonClick(z)}>
              {z}
            </p>
          ))}
        </div>
        <div className='zone-question-modal-close-button-container'>
          <button className='zone-question-modal-close-button' onClick={handleClose}>
            Cerrar
          </button>
        </div>
      </div>
    );
  };

  const allowedPathData = useCallback(
    (cityMatchMap: string) => {
      const cityName = cities.find((c) => c.cityMatchMap === cityMatchMap);
      if (cityName) {
        return cityName.zones.some((z) => {
          const matchCenterAssignment = centerAssignment
            .filter((obj) => {
              if (level === UserLevels.levelOne) {
                return obj.levelOne === auth.user?.userUsername;
              } else if (level === UserLevels.levelTwo) {
                return obj.levelTwo === auth.user?.userUsername;
              } else if (level === UserLevels.levelThree) {
                return obj.levelThree === auth.user?.userUsername;
              }
              return obj;
            })
            .find(
              (cA) =>
                cA.zoneGlobalDescrp === globalZone &&
                subCategories.includes(cA.description) &&
                cA.zoneDescrp === z
            );
          if (matchCenterAssignment || userTypeAllowed) {
            return true;
          }
          return false;
        });
      }
    },
    [cities, centerAssignment, auth, globalZone, level, subCategories, userTypeAllowed]
  );

  const [allDisabled, setAllDisabled] = useState<boolean>(false);

  useEffect(() => {
    const svgElement = svgRef.current as SVGSVGElement | null;
    if (!svgElement) return;
    setAllDisabled(true);
    cities
      .map((c) => c.cityMatchMap)
      .forEach((cityMatchMap) => {
        const pathElement = svgElement.querySelector(`.${cityMatchMap}`);
        if (pathElement && allowedPathData(cityMatchMap)) {
          setAllDisabled(false);

          pathElement.addEventListener('click', () => onClickFunction(cityMatchMap));
          pathElement.setAttribute('fill', '#19DE8B');
          pathElement.setAttribute('cursor', 'pointer');
        }
      });
  }, [centerAssignment, cities, showModal, allowedPathData, onClickFunction]);

  const AllZonesButton: FunctionComponent = () => {
    const onClickAllZones = () => {
      if (auth.user?.userTypeName.includes(UserTypeValues.boss)) {
        const allZones = cities.reduce((acc: string[], obj) => [...acc, ...obj.zones], []);
        router.push(
          `/operaciones/${moduleOption}/${globalZone}/${level}/${subCategories}/${allZones}`
        );
      }
    };
    return (
      <Button onClick={() => onClickAllZones()} variant='contained' color='primary'>
        Todas las zonas
      </Button>
    );
  };

  return (
    <div className='center-zone-map-page-container'>
      <div className='center-zone-map-page-top-buttons'>
        <BackButton route={`/mapa-zona-global/${optionType}`} />
        {!loading && auth.user?.userTypeName.includes(UserTypeValues.boss) ? (
          <AllZonesButton />
        ) : null}
      </div>

      <ZoneQuestionModal />
      <div className='center-zone-map-page-title-container'>
        <h2>{title}</h2>
      </div>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          {allDisabled && (
            <GenericInfoTag
              alertTitle='Consulta al administrador'
              alert='No se encontraron zonas asignas a tu usuario.'
            />
          )}

          <div className='center-zone-map-buttons-section'>{CenterZoneMap(svgRef)}</div>
        </Fragment>
      )}
    </div>
  );
};
export default connect(({ auth }: AppState) => ({ auth }))(CenterZoneMapPage);
