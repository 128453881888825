import { ReportePieChart } from '../types';
import { WebEntity } from './types';

const GastosPieChartEntity: WebEntity<ReportePieChart> = {
  name: 'gastosPieChart1',
  endpoint: 'reportes/gastos/pieChart',
  referenceColumn: '_id',
  tableColumns: []
};

export default GastosPieChartEntity;
