import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Path } from 'history';
import BackButton from '../components/BackButton';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/reporterPage.css';

const ReporterPage: FunctionComponent = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const router = useHistory();

  const buttons: Array<{
    name: string;
    href: Path;
    color: string;
    img: string;
  }> = [
    {
      name: 'Dinámica de Gastos',
      href: '/reporteria/dinamica-gastos',
      color: '#ECBA48',
      img: 'dinamicaGastos'
    },
    {
      name: 'Reportes de Volúmenes',
      href: '/reporteria/volumenes',
      color: '#497BB5',
      img: 'reporteVolumen'
    }
  ];

  return (
    <div className='reporter-page-container'>
      <BackButton route='/' />
      <div className='reporter-page-buttons-section'>
        {buttons.map((b, idx) => (
          <button
            key={idx}
            className='reporter-page-button'
            onClick={() => router.push(b.href)}
            onMouseEnter={() => setHoveredIndex(idx)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{
              borderColor: hoveredIndex === idx ? b.color : '#dfdfdf'
            }}>
            <p>{b.name}</p>
            <GenericIcons icon={b.img} imageStyle={{ maxWidth: '100%' }} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ReporterPage;
