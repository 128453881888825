import 'bootstrap/dist/css/bootstrap.min.css';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import logo from '../img/ccu_logo.png';
import { Helmet } from 'react-helmet';
import { connect, ConnectedComponent } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser, logout } from '../actions/authActions';
import '../css/AppHome.css';
import Pages, { Maintainers, Reports, Inconsistencies } from '../pages';
import AppNavbar from './AppNavbar';
import { AppLink } from './types';
import GenericContainerSidebar from './generics/GenericContainerSidebar/GenericContainerSidebar';
import Logout from './auth/Logout';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import Login from './auth/Login';
import Swal from 'sweetalert2';
import { UserTypeValues } from '../types';

export type SidebarItem = {
  itemId: string;
  name: string;
  allowedUserTypes: Array<UserTypeValues | undefined>;
  childOrComponent?:
    | Array<SidebarItem>
    | { path: string; component: ConnectedComponent<any, any> | React.ComponentType<{}> };
  hidden?: boolean;
  icon?: string;
  disabled?: boolean;
};

const sideBarItems: Array<SidebarItem> = [
  {
    itemId: '01',
    name: 'Inicio',
    icon: 'HouseIcon',
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/',
      component: Pages.WelcomePage
    }
  },
  {
    itemId: '02',
    name: 'Mantenedores',
    icon: 'mantainer',
    allowedUserTypes: [UserTypeValues.admin],
    childOrComponent: [
      {
        itemId: '0201',
        name: 'Mantenedores SD',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: [
          {
            itemId: '020101',
            name: 'Gestionador de Zonas',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02010101',
                name: 'Centro de Costos',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/zonas/centro-de-costo',
                  component: Maintainers.CostCenterCRUDMaintainer
                }
              },
              {
                itemId: '02010102',
                name: 'Sub Centro de costos',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/zonas/sub-cost-center',
                  component: Maintainers.SubCostCentersCRUDMaintainer
                }
              },
              {
                itemId: '02010103',
                name: 'Zonas',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/zonas/zona',
                  component: Maintainers.ZoneCRUDMaintainer
                }
              },
              {
                itemId: '02010104',
                name: 'Zona Global',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/zonas/zona-global',
                  component: Maintainers.ZoneGlobalCRUDMaintainer
                }
              },
              {
                itemId: '02010105',
                name: 'Encargados por Zona',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/zonas/zona-encargados',
                  component: Maintainers.AreaManagerCRUDMaintainer
                }
              }
            ]
          },
          {
            itemId: '020102',
            name: 'Gestionador Presupuesto',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02010201',
                name: 'Tipo Presupuesto',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/presupuesto/tipo-presupuesto',
                  component: Maintainers.SDBudgetTypeCRUDMaintainer
                }
              },
              {
                itemId: '02010202',
                name: 'Presupuesto Anual',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/presupuesto/presupuesto-anual',
                  component: Maintainers.SDBudgetCRUDMaintainer
                }
              }
            ]
          },
          {
            itemId: '020103',
            name: 'Gestionador Cuentas',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02010301',
                name: 'Categorias',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/categorias',
                  component: Maintainers.CategoriasCRUDMaintainer
                }
              },
              {
                itemId: '02010302',
                name: 'Cuentas',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/SD/cuentas',
                  component: Maintainers.DictionaryCcCRUDMaintainer
                }
              }
            ]
          }
        ]
      },
      {
        itemId: '0202',
        name: 'Mantenedores Porteo',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: [
          {
            itemId: '020201',
            name: 'Gestionador de Tarifas',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02020101',
                name: 'Tarifas',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/tarifas',
                  component: Maintainers.TariffCRUDMaintainerConnected
                }
              },
              {
                itemId: '02020102',
                name: 'Tipo de Transportistas',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/tipo-transportista',
                  component: Maintainers.CarrierTypeCRUDMaintainer
                }
              }
            ]
          },
          {
            itemId: '020202',
            name: 'Gestionador de Presupuesto',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02020201',
                name: 'Tipo Presupuesto',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/presupuesto/tipo-presupuesto',
                  component: Maintainers.PorteoBudgetTypeCRUDMaintainer
                }
              },
              {
                itemId: '02020202',
                name: 'Presupuesto Anual',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/presupuesto/presupuesto-anual',
                  component: Maintainers.PorteoBudgetCRUDMaintainer
                }
              }
            ]
          },
          {
            itemId: '020203',
            name: 'Gestionador de Conceptos',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: [
              {
                itemId: '02020301',
                name: 'Conceptos',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/conceptos',
                  component: Maintainers.ConceptCRUDMaintainer
                }
              },
              {
                itemId: '02020302',
                name: 'Sub Conceptos',
                allowedUserTypes: [UserTypeValues.admin],
                childOrComponent: {
                  path: '/mantenedores/porteo/sub-conceptos',
                  component: Maintainers.SubConceptCRUDMaintainer
                }
              }
            ]
          },
          {
            itemId: '020204',
            name: 'Glosa',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/glosa',
              component: Maintainers.GlossCRUDMaintainerConnected
            }
          },
          {
            itemId: '020205',
            name: 'Zonas Porteo',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/zona-porteo',
              component: Maintainers.ZonePortingCRUDMaintainer
            }
          },
          {
            itemId: '020206',
            name: 'Cajas Equivalentes',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/cajas-equivalentes',
              component: Maintainers.EquivalentBoxCRUDMaintainerConnected
            }
          },
          {
            itemId: '020207',
            name: 'Mayor Porteo',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/mayor',
              component: Pages.MajorPortingPageConnected
            }
          },
          {
            itemId: '020208',
            name: 'Camiones Fijos',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/camiones-fijos',
              component: Maintainers.PermanentTruckCRUDMaintainerConnected
            }
          },
          {
            itemId: '020209',
            name: 'Glosa x Retención',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/glosa-x-retencion',
              component: Maintainers.CarrierZoneGlossCRUDMaintainer
            }
          },
          {
            itemId: '020210',
            name: 'NS Emprendedores',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/porteo/cs-emprendedores',
              component: Maintainers.ContractorTariffCRUDMaintainerConnected
            }
          }
        ]
      },
      {
        itemId: '0203',
        name: 'Mantenedores Acarreo',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: [
          {
            itemId: '020301',
            name: 'Tiempo De Ruta',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/acarreo/tiempo-de-ruta',
              component: Maintainers.TimeRouteCRUDMaintainer
            }
          },
          {
            itemId: '020302',
            name: 'Variables Macroeconomicas',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/acarreo/variables-macro',
              component: Pages.ForeignExchangePage
            }
          },
          {
            itemId: '020303',
            name: 'Tarifas Transportistas',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/mantenedores/acarreo/contrato-transportistas',
              component: Maintainers.CarrierRouteCarriageCRUDMaintainer
            }
          }
        ]
      },
      {
        itemId: '0204',
        name: 'Transportistas',
        icon: 'truckIcon2',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: {
          path: '/mantenedores/transportistas',
          component: Maintainers.CarrierCRUDMaintainer
        }
      },
      {
        itemId: '0205',
        name: 'Calendario',
        icon: 'calendarIcon',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: {
          path: '/mantenedores/calendario',
          component: Maintainers.FreightTimeMasterCRUDMaintainerConnected
        }
      }
    ]
  },
  {
    itemId: '03',
    name: 'Administración',
    icon: 'mantainer',
    allowedUserTypes: [UserTypeValues.admin],
    childOrComponent: [
      {
        itemId: '0301',
        name: 'Usuarios',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: {
          path: '/administracion/usuario',
          component: Maintainers.UsuarioCRUDMaintainer
        }
      },
      {
        itemId: '0302',
        name: 'Gestionador de Mapa Centro',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: {
          path: '/administracion/mapa',
          component: Maintainers.MapCRUDMaintainer
        }
      },
      {
        itemId: '0303',
        name: 'Gestionador de Notificaciones',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: {
          path: '/administracion/notificaciones',
          component: Maintainers.NotificationMaintainer
        }
      }
    ]
  },
  {
    itemId: '04',
    name: 'Inconsistencias',
    icon: 'report',
    allowedUserTypes: [UserTypeValues.admin],
    childOrComponent: [
      {
        itemId: '41',
        name: 'Porteo',
        allowedUserTypes: [UserTypeValues.admin],
        childOrComponent: [
          {
            itemId: '040101',
            name: 'Retencion por Contingencia',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/reporte/inconsistencias/porteo/retencion-contingencia',
              component: Inconsistencies.ContingencyWithholdingInconsistenciesReport
            }
          },
          {
            itemId: '040102',
            name: 'Extras',
            allowedUserTypes: [UserTypeValues.admin],
            childOrComponent: {
              path: '/reporte/inconsistencias/porteo/extras',
              component: Inconsistencies.FreightExtraInconsistenciesReport
            }
          }
        ]
      }
    ]
  },
  {
    itemId: '05',
    name: 'Reportes',
    icon: 'report',
    allowedUserTypes: [UserTypeValues.admin, UserTypeValues.boss],
    childOrComponent: [
      {
        itemId: '0501',
        name: 'Totales Generales',
        icon: 'report',
        allowedUserTypes: [UserTypeValues.admin, UserTypeValues.boss],
        childOrComponent: {
          path: '/reporte/totales-generales',
          component: Maintainers.GeneralReportMaintainer
        }
      }
    ]
  },
  {
    itemId: '06',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/modulos',
      component: Pages.ModulesPage
    }
  },
  {
    itemId: '07',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/porteo',
      component: Pages.FreightPage
    }
  },
  {
    itemId: '08',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/acarreo',
      component: Pages.HaulingPage
    }
  },
  {
    itemId: '09',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mapa-zona-global/:optionType',
      component: Pages.GlobalZoneMapPage
    }
  },
  {
    itemId: '10',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mapa-zona-centro/:optionType/:globalZone/:level/:subCategories',
      component: Pages.CenterZoneMapPage
    }
  },
  {
    itemId: '11',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/operaciones/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Pages.OperationsPage
    }
  },
  {
    itemId: '12',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/porteo/gastos-porteo',
      component: Maintainers.FreightChargesCRUDMaintainerConnected
    }
  },
  {
    itemId: '14',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/porteo/extras-porteo',
      component: Maintainers.FreightExtraCRUDMaintainerConnected
    }
  },
  {
    itemId: '15',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reportes/porteo/retencion-contingencia',
      component: Reports.ContingencyWithHoldingReportConnected
    }
  },
  {
    itemId: '16',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/diferencia-tarifa',
      component: Pages.FareDifferencePage
    }
  },
  {
    itemId: '17',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reportes/porteo/estado-financiero',
      component: Reports.EstimatedClosingPortingReport
    }
  },
  {
    itemId: '18',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/acarreo/ifac',
      component: Pages.IfacPageConnected
    }
  },
  {
    itemId: '19',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/acarreo/extras',
      component: Maintainers.ExtraCarriageCRUDMaintainerConnected
    }
  },
  {
    itemId: '20',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reporte/acarreo/uen',
      component: Reports.ReportUENCRUDComponentConnected
    }
  },
  {
    itemId: '21',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/acarreo/flete',
      component: Pages.ShipmentCarriagePage
    }
  },
  {
    itemId: '22',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reporte/acarreo/polinomio',
      component: Reports.PolynomialCRUDComponentConnected
    }
  },
  {
    itemId: '23',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/acarreo/resumen',
      component: Pages.CarriageResumePage
    }
  },
  {
    itemId: '24',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/SD/gastos/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.ExpensesCRUDMaintainerConnected
    }
  },
  {
    itemId: '25',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/SD/HFM/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.HFMCRUDMaintainerConnected
    }
  },
  {
    itemId: '26',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/volumen',
      component: Maintainers.VolumenCRUDMaintainerConnected
    }
  },
  {
    itemId: '27',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reporteria',
      component: Pages.ReporterPage
    }
  },
  {
    itemId: '28',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reporteria/dinamica-gastos',
      component: Reports.ReporteGastos
    }
  },
  {
    itemId: '29',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reporteria/volumenes',
      component: Reports.ReporteVolumen
    }
  },
  {
    itemId: '30',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/estimado-cierre/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.EstimatedClosingCRUDMaintainer
    }
  },
  {
    itemId: '31',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/estimado-cierre-pendiente/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.EstimatedClosingPendingMaintainer
    }
  },
  {
    itemId: '32',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/estimado-cierre-rechazado/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.EstimatedClosingRejectedMaintainer
    }
  },
  {
    itemId: '33',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/estimado-cierre-global/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.EstimatedGlobalClosingMaintainer
    }
  },
  {
    itemId: '34',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/mantenedores/totales-generales/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.GeneralZoneReportMaintainer
    }
  },
  {
    itemId: '35',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/provisiones/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.ProvisionCRUDMaintainer
    }
  },
  {
    itemId: '36',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/provisiones-pendientes/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.PendingProvisionCRUDMaintainer
    }
  },
  {
    itemId: '37',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/provisiones-rechazadas/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.RefusedProvisionMaintainer
    }
  },
  {
    itemId: '38',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/provisiones-aprobadas/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.ApprovedProvisionMaintainer
    }
  },
  {
    itemId: '39',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reclasificacion/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.ReclassificationCRUDMaintainer
    }
  },
  {
    itemId: '40',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reclasificacion-pendientes/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.PendingReclassificationCRUDMaintainer
    }
  },
  {
    itemId: '41',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reclasificacion-rechazadas/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.RefusedReclassificationCRUDMaintainer
    }
  },
  {
    itemId: '42',
    name: '',
    hidden: true,
    allowedUserTypes: [
      UserTypeValues.admin,
      UserTypeValues.boss,
      UserTypeValues.management,
      UserTypeValues.operador,
      UserTypeValues.supervisor
    ],
    childOrComponent: {
      path: '/reclasificacion-aprobadas/:optionType/:globalZone/:level/:subCategories/:zones',
      component: Maintainers.ApprovedReclassificationCRUDMaintainer
    }
  }
];

type Props = {
  auth: AuthState;
  loadUser: () => Promise<void>;
  logout: typeof logout;
};

const App: FunctionComponent<Props> = ({ auth, loadUser, logout }) => {
  const timeoutRef = useRef<number | undefined>(undefined);
  const time = useMemo(() => 1000 * 60 * 30, []); // total = 30 min

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      Swal.fire({
        title: '¡Tu sesión ha expirado!',
        icon: 'error'
      });
      logout();
    }, time);
  };

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    if (auth.token || auth.isAuthenticated) {
      const events = ['mousemove', 'keydown', 'click'];
      const handleActivity = () => {
        resetTimeout();
      };

      events.forEach((event) => window.addEventListener(event, handleActivity));
      resetTimeout();

      return () => {
        events.forEach((event) => window.removeEventListener(event, handleActivity));
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [auth]);

  const allowedRoutes = useMemo(
    () =>
      extractPathAndComponent(sideBarItems).filter(
        (item) =>
          auth.user?.userTypeName.some((u) => item.allowedUserTypes.includes(u)) ||
          item.allowedUserTypes.length === 0 // all user allow
      ),
    [auth]
  );

  const allowedSidebarItems = useMemo(
    () => (auth.user?.userTypeName ? filterHiddenItems(sideBarItems, auth.user?.userTypeName) : []),
    [auth]
  );

  const theme = useTheme();
  return (
    <div className='App'>
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <Router>
          {auth.isAuthenticated ? (
            <GenericContainerSidebar
              img={logo}
              AppNavbar={<AppNavbar sideBarItems={allowedSidebarItems} />}
              linkTopNav={'/'}
              logout={<Logout />}
              auth={auth}>
              <Switch>
                {allowedRoutes.map(({ path, component }, idx) => (
                  <Route key={`main_menu_${idx}`} exact path={path} component={component} />
                ))}
                <Route path='/404' component={Pages.NotFoundPage} />
                <Redirect to='/404' />
              </Switch>
            </GenericContainerSidebar>
          ) : (
            <Login />
          )}
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser, logout })(App);

const extractPathAndComponent = (
  items: Array<SidebarItem>
): Array<{
  path: string;
  component: ConnectedComponent<any, any> | React.ComponentType<{}>;
  allowedUserTypes: Array<UserTypeValues | undefined>;
}> => {
  let result: Array<{
    path: string;
    component: ConnectedComponent<any, any> | React.ComponentType<{}>;
    allowedUserTypes: Array<UserTypeValues | undefined>;
  }> = [];

  items.forEach((item) => {
    if (item.childOrComponent) {
      if (Array.isArray(item.childOrComponent)) {
        result = result.concat(extractPathAndComponent(item.childOrComponent));
      } else if ('path' in item.childOrComponent && 'component' in item.childOrComponent) {
        result.push({
          path: item.childOrComponent.path,
          component: item.childOrComponent.component,
          allowedUserTypes: item.allowedUserTypes
        });
      }
    }
  });

  return result;
};

const filterHiddenItems = (
  items: Array<SidebarItem>,
  userType: UserTypeValues[]
): Array<SidebarItem> => {
  return items.reduce<Array<SidebarItem>>((filteredItems, item) => {
    if (item.hidden || !userType.some((u) => item.allowedUserTypes.includes(u))) {
      return filteredItems;
    }

    let filteredChildOrComponent;
    if (Array.isArray(item.childOrComponent)) {
      filteredChildOrComponent = filterHiddenItems(item.childOrComponent, userType);
    } else {
      filteredChildOrComponent = item.childOrComponent;
    }

    if (Array.isArray(filteredChildOrComponent) && filteredChildOrComponent.length === 0) {
      filteredChildOrComponent = undefined;
    }

    filteredItems.push({
      ...item,
      childOrComponent: filteredChildOrComponent
    });

    return filteredItems;
  }, []);
};
