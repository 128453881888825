import axios from 'axios';
import { WebEntityName } from '../entities/types';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY,
  GET_ENTITY,
  LOADING_ENTITY,
  SELECTED_ENTITY,
  SINGLE_SELECTED_ENTITY,
  NOTIFY_MESSAGE_ENTITY,
  REFRESH_ENTITY
} from '../reducers/types';
import { AppDispatch, AppGetState } from '../store';
import { BasicEntity } from '../types';
import { tokenConfig } from '../utils/header';
import { fetchDataOnStream, fetchPaginatedData } from '../utils/request';
import { loadQuery } from '../utils/utils';
import { handleError } from '../components/PopAlert';
import { MRT_RowData } from 'material-react-table';

export const getEntitiesAction =
  (
    entityName: WebEntityName,
    endpoint: string,
    {
      GETPath = '',
      query = {},
      fetchByNRows = 0,
      onParallel = 1,
      limitRows = 0
    }: {
      GETPath?: string;
      query?: any;
      fetchByNRows?: number;
      onParallel?: number;
      limitRows?: number;
    }
  ) =>
  (pageNumber: number = 0, pageSize: number = 10, filters?: any) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState
  ): Promise<{ availablePages: number; totalItemsCount: number }> => {
    // Loading ....
    dispatch(setEntityLoading(entityName, true));

    try {
      const localQuery = await loadQuery(query);
      const localEndpoint = GETPath ? `${endpoint}/${GETPath}` : endpoint;

      // Paginated Fetch
      if (pageNumber > 0) {
        const res = await fetchPaginatedData(localEndpoint, pageNumber, pageSize, {
          ...localQuery,
          ...filters
        });

        const { data, availablePages, totalItemsCount } = res || {};

        dispatch({
          type: GET_ENTITY,
          payload: data || [],
          entityName
        });

        dispatch(setEntityLoading(entityName, false));

        return { availablePages: availablePages || 0, totalItemsCount };
      }

      const { passwordLessToken } = getState().auth.impersonation || {
        passwordLessToken: undefined
      };

      // Common Fetch
      await fetchDataOnStream(endpoint, {
        callback: (data) =>
          dispatch({
            type: GET_ENTITY,
            payload: data,
            entityName
          }),
        GETPath,
        query: { ...localQuery, ...filters },
        passwordLessToken,
        limitRows,
        fetchByNRows,
        onParallel
      });

      dispatch(setEntityLoading(entityName, false));
    } catch (error) {
      console.log(error);
      // In case of error clean the entity collaction
      dispatch({
        type: GET_ENTITY,
        payload: [],
        entityName
      });

      dispatch(setEntityLoading(entityName, false));
      handleError({ error, entityName, dispatch });
    }

    return { availablePages: 0, totalItemsCount: 0 };
  };

export const setSelectedEntityAction =
  (entityName: WebEntityName) =>
  <T extends BasicEntity>(entities: T[]) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: SELECTED_ENTITY,
      payload: entities,
      entityName
    });
  };

export const setSingleSelectedEntityAction =
  (entityName: WebEntityName) =>
  <T extends BasicEntity>(entity: T) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: SINGLE_SELECTED_ENTITY,
      payload: entity,
      entityName
    });
  };

export const voidEntitiesAction =
  (entityName: WebEntityName) =>
  () =>
  async (dispatch: AppDispatch): Promise<{ availablePages: number; totalItemsCount: number }> => {
    // Loading ....
    dispatch(setEntityLoading(entityName, true));

    try {
      dispatch({
        type: GET_ENTITY,
        payload: [],
        entityName
      });
      dispatch(setEntityLoading(entityName, false));

      return { availablePages: 0, totalItemsCount: 0 };
    } catch (error) {
      console.log(error);
      handleError({ error, entityName, dispatch });
    }

    return { availablePages: 0, totalItemsCount: 0 };
  };

export const deleteEntityAction =
  (entityName: WebEntityName, endpoint: string) =>
  (id: string | string[]) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const entityIds = Array.isArray(id) ? id : [id];
      const response = await axios.patch(
        `/api/${endpoint}`,
        { action: 'delete', entityIds },
        tokenConfig(getState)
      );
      const { deletedEntity } = response.data;

      dispatch({
        type: DELETE_ENTITY,
        payload: entityIds,
        entityName
      });

      return deletedEntity;
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

export const addEntityAction =
  (entityName: WebEntityName, endpoint: string) =>
  <T>(entity: T | T[]) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const entities = Array.isArray(entity) ? entity : [entity];

    try {
      const res = await axios.post(`/api/${endpoint}`, entities, tokenConfig(getState));

      dispatch({
        type: ADD_ENTITY,
        payload: res.data,
        entityName
      });

      return res;
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }

    return null;
  };

export const multiAddEntityAction =
  (entityName: WebEntityName, endpoint: string) =>
  <T>(entity: T | T[]) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const entities = Array.isArray(entity) ? entity : [entity];

    try {
      const res = await axios.post(`/api/${endpoint}`, entities, tokenConfig(getState));

      dispatch({
        type: ADD_ENTITY,
        payload: res.data,
        entityName
      });

      return res;
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }

    return null;
  };

export const editEntityAction =
  (entityName: WebEntityName, endpoint: string) =>
  <T>(entity: T) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const data = (
        await axios.patch<{ data: MRT_RowData }>(
          `/api/${endpoint}`,
          { action: 'edit', entityBody: entity },
          tokenConfig(getState)
        )
      ).data;

      dispatch({
        type: EDIT_ENTITY,
        payload: data,
        entityName
      });
      return data;
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

export const notifyMessageEntityAction =
  (entityName: WebEntityName) => (msg: string) => (dispatch: AppDispatch, _: AppGetState) => {
    dispatch({
      type: NOTIFY_MESSAGE_ENTITY,
      payload: msg,
      entityName
    });
  };

export const localEditEntityAction =
  (entityName: WebEntityName) =>
  <T>(updatedEntity: BasicEntity) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      console.log('pase por aca');
      console.log(updatedEntity);
      dispatch({
        type: EDIT_ENTITY,
        payload: updatedEntity,
        entityName
      });
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

export const setEntityLoading = (
  entityName: WebEntityName,
  loading: boolean = true
): { type: typeof LOADING_ENTITY; payload: boolean; entityName: WebEntityName } => {
  return {
    type: LOADING_ENTITY,
    payload: loading,
    entityName
  };
};

export const refreshEntity = (entityName: WebEntityName) => async (dispatch: AppDispatch) => {
  dispatch({
    type: REFRESH_ENTITY,
    payload: null,
    entityName: entityName
  });
};

export const loadEntityDataAction =
  (entityName: WebEntityName) =>
  <T extends BasicEntity>(data: T[]) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch({
        type: GET_ENTITY,
        payload: data,
        entityName
      });
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };
