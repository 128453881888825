import { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { useHistory } from 'react-router-dom';
import { Path } from 'history';
import { AuthState } from '../reducers/types';
import { UserTypeValues, modules } from '../types';
import BackButton from '../components/BackButton';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/modulesPage.css';

interface Props {
  auth: AuthState;
}

const ModulesPage: FunctionComponent<Props> = ({ auth }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const router = useHistory();

  const buttons: Array<{
    name: string;
    img: string;
    onlyAdmin: boolean;
    href: Path;
    color: string;
  }> = [
    {
      name: modules.SD.name,
      img: 'moneyStressIcon',
      onlyAdmin: false,
      href: `/mapa-zona-global/${modules.SD.abbreviation}`,
      color: '#78A434'
    },
    {
      name: modules.PORTEO.name,
      img: 'porteoIcon',
      onlyAdmin: true,
      href: '/porteo',
      color: '#497BB5'
    },
    {
      name: 'Planificación Acarreo',
      img: 'acarreoIcon',
      onlyAdmin: true,
      href: '/acarreo',
      color: '#ECBA48'
    }
  ];
  return (
    <div className='modules-container'>
      <BackButton route='/' />
      <div className='modules-buttons-section'>
        {buttons.map((b, idx) => {
          if (b.onlyAdmin && !auth.user?.userTypeName.includes(UserTypeValues.admin)) {
            return null;
          }
          return (
            <button
              key={idx}
              className='modules-button'
              onClick={() => router.push(b.href)}
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                borderColor: hoveredIndex === idx ? b.color : '#dfdfdf'
              }}>
              <p>{b.name}</p>
              <GenericIcons icon={b.img} imageStyle={{ maxWidth: '100%' }} />
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default connect(({ auth }: AppState) => ({ auth }))(ModulesPage);
