import Alert, { AlertColor, AlertPropsColorOverrides } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { OverridableStringUnion } from '@mui/types';

const GenericInfoTag = ({
  alert,
  alertTitle,
  severity = 'info'
}: {
  alert: string;
  alertTitle?: string;
  severity?: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
}) => {
  return (
    <Alert severity={severity}>
      {alertTitle ? <AlertTitle>{alertTitle}</AlertTitle> : null}
      {alert}
    </Alert>
  );
};

export default GenericInfoTag;
