import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { UserTypeValues } from '../types';

interface IProps {
  auth: AuthState;
}

const LandingPage: FunctionComponent<IProps> = ({ auth }) => {
  const { isAuthenticated, user } = auth;

  if (isAuthenticated) {
    if (user!.userTypeName.includes(UserTypeValues.admin)) {
      return <Redirect to='/admin' />;
    } else if (user!.userTypeName.includes(UserTypeValues.operador)) {
      return <Redirect to='/mantenedores' />;
    }
  }

  return null;
};

export default connect(({ auth }: AppState) => ({ auth }))(LandingPage);
