import * as E from '../entities';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SignalCellularAltOutlined } from '@mui/icons-material';
import { generateCRUD } from './CRUDGenerator';
import {
  DateAndGlobalZoneFiltersConnected,
  MultipleApprovalClosingEstimateButton,
  MultipleApprovalClosingEstimatePendingButton,
  IfacFiltersConnected
} from './SecondaryButton';
import GenericTopTableCGFCCU from './GenericTopTableCGFCCU';
import {
  BudgetCarriage,
  FreightCharges,
  HFM,
  IfacType,
  ShipmentAuditingCarriage,
  modules,
  PermanentTruck,
  ShipmentCarriage,
  SimpleObject,
  UserTypeValues,
  ShipmentCarriagePXQ,
  ResumeShipmentCarriage,
  ReporteGastos,
  ReportePieChart,
  ReporteVolumen
} from '../types';
import { FunctionComponent } from 'react';
import { AuthState } from '../reducers/types';
import { MRT_RowData } from 'material-react-table';
import { CardAlertProps } from './CardAlert';
import { dateFormatFn, formatStringNumber, spanishDayNames } from '../utils/utils';
import { WebEntity } from '../entities/types';

const columnsHeaderCCUStyle = {
  backgroundColor: '#64a70b',
  fontSize: 15,
  border: '2px solid white',
  color: 'white',
  '&[data-pinned="true"]:before': {
    backgroundColor: '#64a70b !important'
  }
};

export const TopTableComponent: FunctionComponent<{
  titleTable: string;
  auth: AuthState;
  tableData: MRT_RowData[];
  isloading: boolean;
  backButtonPathName?: string;
  additionalCardAlerts?: CardAlertProps[];
  CustomComponent?: JSX.Element;
  omitGlobalDataCard?: boolean;
}> = ({
  titleTable,
  auth,
  tableData,
  isloading,
  backButtonPathName,
  additionalCardAlerts,
  CustomComponent,
  omitGlobalDataCard
}) => {
  const quantityRows = isloading ? 0 : tableData.length > 0 ? tableData.length : 0;

  const arrowName = auth?.user?.userTypeName.includes(UserTypeValues.boss)
    ? UserTypeValues.management
    : auth?.user?.zoneGlobalDescrp.toString();

  const cardAlerts: CardAlertProps[] = omitGlobalDataCard
    ? []
    : [
        {
          title: 'Datos Globales',
          icon: <SignalCellularAltOutlined fontSize={'large'} />,
          numberInfo: formatStringNumber(quantityRows.toString())
        }
      ];

  if (additionalCardAlerts && additionalCardAlerts.length > 0) {
    cardAlerts.push(...additionalCardAlerts);
  }
  return (
    <GenericTopTableCGFCCU
      titleTable={titleTable}
      arrowName={arrowName?.toString() || ''}
      cardAlerts={cardAlerts}
      backButtonPathName={backButtonPathName}
      CustomComponent={CustomComponent}
    />
  );
};

// ADMINISTRACIÓN ==========================================

export const UsuarioCRUD = generateCRUD({
  webEntity: E.UserEntity,
  title: 'Administración Usuario',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Usuario'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    delete: true,
    add: true,
    edit: true,
    select: { enable: true }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const MapCRUD = generateCRUD({
  webEntity: E.MapEntity,
  title: 'Administración Mapa Centro',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Mapa Centro'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  }
});

export const NotificationCRUD = generateCRUD({
  webEntity: E.NotificationEntity,
  title: 'Administración Notificaciones',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Notificaciones'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const NotificationTypeCRUD = generateCRUD({
  webEntity: E.NotificationTypeEntity,
  title: 'Tipos de Notificaciones',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Tipos de Notificaciones'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

// VOLUMEN ==================================================

export const VolumenCRUD = generateCRUD({
  webEntity: E.VolumenEntity,
  title: 'Administración de Volumen',
  customTopTable: () => <></>,
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.VolumenEntity}
          viewsDate={['year', 'month']}
          omitZoneFilter={true}
        />
      )
    }
  ],
  bodyRowProps: ({ row }) => {
    const isEstimated = row.original?.estimated;
    return {
      sx: {
        backgroundColor: isEstimated ? '#ECBA48' : 'transparent'
      }
    };
  }
});

export const ZoneGlobalBudgetVolumeCRUD = generateCRUD({
  webEntity: E.ZoneGlobalBudgetVolumeEntity,
  title: 'Zona Global Presupuesto Volumen',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Zona Global Presupuesto Volumen'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const ZoneBudgetVolumeCRUD = generateCRUD({
  webEntity: E.ZoneBudgetVolumeEntity,
  title: 'Zona Presupuesto Volumen',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Zona Presupuesto Volumen'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const DistributionCenterBudgetVolumeCRUD = generateCRUD({
  webEntity: E.DistributionCenterBudgetVolumeEntity,
  title: 'CD Presupuesto Volumen',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='CD Presupuesto Volumen'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const GroupBudgetVolumeCRUD = generateCRUD({
  webEntity: E.GroupBudgetVolumeEntity,
  title: 'Grupo Presupuesto Volumen',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Grupo Presupuesto Volumen'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const PresupuestoVolumenCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.PresupuestoVolumenEntity,
    title: 'Presupuesto de Volumen',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['mes']
    },
    enableStickyFooter: true
  });

// ESTIMADOS CIERRE =======================================

export const EstimatedClosingCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.EstimatedClosingEntity,
    title: 'Administración estimado cierre',
    query,
    customTopTable: ({ auth, tableData, isloading }) => {
      const offBudget = tableData.reduce((acc, obj) => {
        if (
          (!isNaN(Number(obj?.presupuestoAnualTotal)) &&
            !isNaN(Number(obj?.estimatedTotal)) &&
            Number(obj?.presupuestoAnualTotal) === 0 &&
            Number(obj?.estimatedTotal) !== 0) ||
          obj?.porcentajeDiferencia * 100 < -15 ||
          obj?.porcentajeDiferencia * 100 > 10
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);

      return (
        <TopTableComponent
          titleTable='Administración estimado cierre'
          auth={auth}
          tableData={tableData}
          isloading={isloading}
          additionalCardAlerts={[
            {
              title: 'Estimado vs presupuesto',
              icon: <InfoOutlinedIcon color='error' fontSize={'large'} />,
              numberInfo: offBudget.toString(),
              variant: 'error'
            }
          ]}
        />
      );
    },
    additionalTableActions: ({ selected }) => {
      const rowsSelected = selected.map((s) => {
        const id = s.original._id;
        const offBudget =
          (!isNaN(Number(s.original?.presupuestoAnualTotal)) &&
            !isNaN(Number(s.original?.estimatedTotal)) &&
            Number(s.original?.presupuestoAnualTotal) === 0 &&
            Number(s.original?.estimatedTotal) !== 0) ||
          Number(s.original?.porcentajeDiferencia) * 100 < -15 ||
          Number(s.original?.porcentajeDiferencia) * 100 > 10;
        return { id, offBudget };
      });
      return <MultipleApprovalClosingEstimateButton rowsSelected={rowsSelected} />;
    },
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: true,
      edit: false,
      select: { enable: true }
    },
    initialState: {
      grouping: ['categoriesName'],
      columnVisibility: {
        _id: false
      }
    },
    bodyRowProps: ({ row }) => {
      const offBudget =
        (!isNaN(Number(row?.original?.presupuestoAnualTotal)) &&
          !isNaN(Number(row?.original?.estimatedTotal)) &&
          Number(row?.original?.presupuestoAnualTotal) === 0 &&
          Number(row?.original?.estimatedTotal) !== 0) ||
        row?.original?.porcentajeDiferencia * 100 > 10 ||
        row?.original?.porcentajeDiferencia * 100 < -15;
      return {
        sx: {
          border: `2px solid ${offBudget ? 'red' : 'transparent'}`
        }
      };
    }
  });

export const EstimatedClosingPendingCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.EstimatedClosingPendingEntity,
    title: 'Estimado cierre pendientes',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Estimado cierre pendientes'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    additionalTableActions: ({ selected }) => {
      const idsSelected = selected.map((s) => s.original._id);
      return <MultipleApprovalClosingEstimatePendingButton idsSelected={idsSelected} />;
    },
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: true }
    },
    initialState: {
      columnPinning: {
        right: ['options']
      },
      columnVisibility: {
        _id: false
      }
    }
  });

export const EstimatedClosingRejectedCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.EstimatedClosingRejectedEntity,
    title: 'Estimados cierre Rechazados',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Estimados cierre Rechazados'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: true,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      columnPinning: {
        left: ['options']
      }
    }
  });

export const EstimatedGlobalClosingCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.EstimatedGlobalClosingEntity,
    title: 'Estimados cierre Global',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Estimados cierre Global'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['categoriesName'],
      columnVisibility: {
        _id: false,
        costCenterSap: false,
        zoneDescrp: false
      }
    },
    additionalTopTableActions: [
      {
        component: (
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.EstimatedGlobalClosingEntity}
            viewsDate={['year', 'month']}
            additionalParams={query}
            omitZoneFilter={true}
          />
        )
      }
    ]
  });

// ADMINISTRAR =============================================

export const ExpensesCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ExpensesEntity,
    title: 'Administración de Gastos',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true,
    initialState: {
      grouping: ['categoriesName'],
      columnPinning: {
        right: ['total']
      }
    }
  });

export const HFMCRUD = (entity: WebEntity<HFM>) =>
  generateCRUD({
    webEntity: entity,
    title: 'Administración HFM',
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    additionalTopTableActions: [
      {
        component: (
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.HFMEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
          />
        )
      }
    ],
    initialState: {
      grouping: ['categoriesName']
    },
    enableStickyFooter: true
  });

// CIERRE MES ==============================================

// PROVISION

export const ProvisionCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ProvisionEntity,
    title: 'Provisiones',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Provisiones'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    }
  });

export const PendingProvisionCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.PendingProvisionEntity,
    title: 'Provisiones pendientes',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Provisiones pendientes'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    }
  });

export const RefusedProvisionCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.RefusedProvisionEntity,
    title: 'Provisiones Rechazados',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Provisiones Rechazados'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: true,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    }
  });

export const ApprovedProvisionCRUD = generateCRUD({
  webEntity: E.ApprovedProvisionEntity,
  title: 'Provisiones Aprobadas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Provisiones Aprobadas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false,
      reference: false,
      dateConversion: false,
      changeType: false,
      referenceDocument: false,
      taxAmount: false,
      taxindicator: false,
      paymentCondition: false,
      paymentMethod: false,
      factureReference: false,
      invoiceExercise: false,
      positionRefFacture: false,
      referenceOne: false,
      referencetwo: false,
      referencethree: false,
      accountClass: false,
      treasuryDate: false,
      elementPep: false,
      orderNumber: false,
      societyGl: false,
      mybank: false,
      accountBank: false,
      distributionChanel: false,
      sectoring: false,
      sellOrganization: false,
      groupSeller: false,
      salesOffice: false,
      materiales: false,
      cmeIndicator: false
    }
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.ApprovedProvisionEntity}
          viewsDate={['year', 'month']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

// RECLASIFICACION

export const ReclassificationCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ReclassificationEntity,
    title: 'Reclasificación',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reclasificación'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['text']
    }
  });

export const PendingReclassificationCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.PendingReclassificationEntity,
    title: 'Reclasificaciones Pendientes',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reclasificaciones Pendientes'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['text']
    }
  });

export const RefusedReclassificationCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.RefusedReclassificationEntity,
    title: 'Reclasificaciones Rechazados',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reclasificaciones Rechazados'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: true,
      select: { enable: false }
    },
    initialState: {
      grouping: ['text']
    }
  });

export const ApprovedReclassificationCRUD = generateCRUD({
  webEntity: E.ApprovedReclassificationEntity,
  title: 'Reclasificaciones Aprobadas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Reclasificaciones Aprobadas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false,
      reference: false,
      dateConversion: false,
      changeType: false,
      referenceDocument: false,
      taxAmount: false,
      taxindicator: false,
      paymentCondition: false,
      paymentMethod: false,
      factureReference: false,
      invoiceExercise: false,
      positionRefFacture: false,
      referenceOne: false,
      referencetwo: false,
      referencethree: false,
      accountClass: false,
      treasuryDate: false,
      elementPep: false,
      orderNumber: false,
      societyGl: false,
      mybank: false,
      accountBank: false,
      distributionChanel: false,
      sectoring: false,
      sellOrganization: false,
      groupSeller: false,
      salesOffice: false,
      materiales: false,
      cmeIndicator: false
    }
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.ApprovedReclassificationEntity}
          viewsDate={['year', 'month']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

// REPORTE GENERAL ZONA

export const GeneralZoneReportCRUD = (query: SimpleObject, backButtonPathName: string) =>
  generateCRUD({
    webEntity: E.GeneralZoneReportEntity,
    title: 'Reporte General Zona',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reporte General Zona'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
        backButtonPathName={backButtonPathName}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    additionalTopTableActions: [
      {
        component: (
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.GeneralZoneReportEntity}
            viewsDate={['year', 'month']}
            additionalParams={query}
          />
        )
      }
    ],
    initialState: {
      columnVisibility: {
        costCenterSap: false,
        accountSap: false
      }
    },
    enableStickyFooter: true
  });

// REPORTE TOTALES GENERALES

export const GeneralReportCRUD = generateCRUD({
  webEntity: E.GeneralReportEntity,
  title: 'Reporte Totales Generales',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Reporte Totales Generales'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
      backButtonPathName='/'
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.GeneralReportEntity}
          viewsDate={['year', 'month']}
        />
      )
    }
  ],
  initialState: {
    columnVisibility: {
      costCenterSap: false,
      accountSap: false
    },
    grouping: ['categoriesName']
  },
  enableStickyFooter: true
});

// MANTENEDORES SD =========================================

// GESTIONADOR DE ZONAS
export const CostCenterCRUD = generateCRUD({
  webEntity: E.CostCenterEntity,
  title: 'Administración Centro de costo',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Centro de costo'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const SubCostCenterCRUD = generateCRUD({
  webEntity: E.SubCostCenterEntity,
  title: 'Administración de Sub Centro de costos',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Sub Centro de costos'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const ZoneCRUD = generateCRUD({
  webEntity: E.ZoneEntity,
  title: 'Administración Zonas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Zonas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const ZoneGlobalCRUD = generateCRUD({
  webEntity: E.ZoneGlobalEntity,
  title: 'Administración Zonas Globales',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Zonas Globales'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const AreaManagerCRUD = generateCRUD({
  webEntity: E.AreaManagerEntity,
  title: 'Asignacion de Zonas Encargados',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Asignacion de Zonas Encargados'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  },
  initialState: {
    grouping: ['zoneGlobalDescrp']
  }
});

//GESTIONADOR PRESUPUESTO

export const SDBudgetTypeCRUD = generateCRUD({
  webEntity: E.BudgetTypeEntity,
  title: 'Administración de Tipos Presupuestos',
  query: { moduleType: modules.SD.abbreviation },
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Tipos Presupuestos'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const SDBudgetCRUD = generateCRUD({
  webEntity: E.BudgetEntity,
  title: 'Administración de Presupuestos SD',
  query: { moduleType: modules.SD.abbreviation },
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Presupuestos SD'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  enableStickyFooter: true,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    grouping: ['budgetTypeName', 'categoriesName', 'accountSap'],
    columnPinning: {
      left: ['totalGeneral']
    }
  }
});

// GESTIONADOR CUENTAS

export const CategoriesCRUD = generateCRUD({
  webEntity: E.CategoriesEntity,
  title: 'Administración de Categorias',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Categorias'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const DictionaryCcCRUD = generateCRUD({
  webEntity: E.DictionaryCcEntity,
  title: 'Administracion Cuentas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administracion Cuentas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: false,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

// MANTENEDORES PORTEO =====================================

export const CarrierCRUD = generateCRUD({
  webEntity: E.CarrierEntity,
  title: 'Administración Transportista',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Transportista'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['managementTypeName']
  }
});

export const ManagementTypeCRUD = generateCRUD({
  webEntity: E.ManagementTypeEntity,
  title: 'Tipo EETT',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Tipo EETT'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const CarrierTypeCRUD = generateCRUD({
  webEntity: E.CarrierTypeEntity,
  title: 'Administración Tipos de Transportistas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Tipos de Transportistas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const GlossCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.GlossEntity,
    title: 'Administración Glosas',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: true,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    }
  });

export const ZonePortingCRUD = generateCRUD({
  webEntity: E.ZonePortingEntity,
  title: 'Administración Zonas Porteo',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Zonas Porteo'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const ConceptCRUD = generateCRUD({
  webEntity: E.ConceptEntity,
  title: 'Administración de Conceptos',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Conceptos'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const SubConceptCRUD = generateCRUD({
  webEntity: E.SubConceptEntity,
  title: 'Administración Sub Conceptos',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Sub Conceptos'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['conceptName']
  }
});

export const TariffCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.TariffEntity,
    title: 'Administración de tarifas',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: true,
      delete: false,
      edit: true,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false,
        bp: false
      }
    },
    enableStickyFooter: true
  });

//GESTIONADOR PRESUPUESTO

export const PorteoBudgetTypeCRUD = generateCRUD({
  webEntity: E.BudgetTypeEntity,
  title: 'Administración de Tipos Presupuestos',
  query: { moduleType: modules.PORTEO.abbreviation },
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Tipos Presupuestos'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const PorteoBudgetCRUD = generateCRUD({
  webEntity: E.BudgetEntity,
  title: 'Administración de Presupuestos Porteo',
  query: { moduleType: modules.PORTEO.abbreviation },
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración de Presupuestos Porteo'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    grouping: ['budgetTypeName', 'categoriesName', 'accountSap'],
    columnPinning: {
      left: ['totalGeneral']
    }
  }
});

export const FreightChargesCRUD = (
  entity: WebEntity<FreightCharges>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['carrierTypeName'],
      columnVisibility: {
        _id: false
      }
    },
    enableStickyFooter: true
  });

export const EquivalentBoxCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.EquivalentBoxEntity,
    title: 'Administración Cajas Equivalentes',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    },
    enableStickyFooter: true
  });

export const MajorPortingCRUD = (title: string, query: SimpleObject) =>
  generateCRUD({
    webEntity: E.MajorPortingEntity,
    title,
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable={title}
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    },
    enableStickyFooter: true,
    additionalTopTableActions: [
      {
        component: (
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.MajorPortingEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            additionalParams={query}
          />
        )
      }
    ]
  });

export const FreightExtraCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.FreightExtraEntity,
    title: 'Extras Porteo',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true,
    initialState: {
      grouping: ['carrierName']
    }
  });

export const FreightExtraResumeCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.FreightExtraResumeEntity,
    title: 'Resumen Extras Porteo',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true,
    initialState: {
      grouping: ['conceptName'],
      columnVisibility: {
        carrierRut: false,
        bp: false,
        purchaseRequest: false,
        purchaseOrder: false,
        noteOrder: false,
        distributionCenterZona: false,
        distributionCenter: false,
        zonePortingDescription: false
      }
    }
  });

export const FreightExtraGlSapCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.FreightExtraGlSapEntity,
    title: 'GL SAP',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true,
    initialState: {
      grouping: ['carrierName']
    }
  });

export const ContingencyWithholdingCRUD = generateCRUD({
  webEntity: E.ContingencyWithholdingEntity,
  title: 'Retención por Contingencia',
  customTopTable: () => <></>,
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  initialState: {
    grouping: ['date']
  }
});

export const FareDifferenceCRUD = (webEntity: WebEntity<any>, query: SimpleObject) =>
  generateCRUD({
    webEntity,
    title: 'Diferencia de Tarifa',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true
  });

export const FareDifferenceSummaryCRUD = generateCRUD({
  webEntity: E.FareDifferenceSummaryEntity,
  title: 'Resumen Diferencia de Tarifa',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Resumen Diferencia de Tarifa'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.FareDifferenceSummaryEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ],
  initialState: {
    columnPinning: {
      left: ['carrierName']
    }
  }
});

export const EstimatedClosingPortingCRUD = generateCRUD({
  webEntity: E.EstimatedClosingPortingEntity,
  title: 'Estado Financiero',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Estado Financiero'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.EstimatedClosingPortingEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ],
  initialState: {
    grouping: ['typeInfo'],
    columnPinning: {
      left: ['typeInfo', 'carrierTypeName']
    }
  }
});

export const FreightProvisionCRUD = generateCRUD({
  webEntity: E.FreightProvisionEntity,
  title: 'Provision',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Provision'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    editCondition: (row) => {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();

      const date = new Date(dateFormatFn(row.freightProvisionMonth, 'MM-DD-YYYY', true));
      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth();

      const monthDifference = (currentYear - dateYear) * 12 + (currentMonth - dateMonth);

      return !(monthDifference < 0 || monthDifference > 2);
    },
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.FreightProvisionEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

export const PermanentTruckCRUD = (
  entity: WebEntity<PermanentTruck>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    },
    enableStickyFooter: true
  });

export const CarrierZoneGlossCRUD = generateCRUD({
  webEntity: E.CarrierZoneGlossEntity,
  title: 'Glosa x Retención',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Glosa x Retención'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const FreightGlossCRUD = generateCRUD({
  webEntity: E.FreightGlossEntity,
  title: 'Glosas Porteo',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Glosas Porteo'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const ContractorTariffCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ContractorTariffEntity,
    title: 'NS Emprendedores',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    },
    enableStickyFooter: true
  });

// MANTENEDORES ACARREO ====================================

export const TimeRouteCRUD = generateCRUD({
  webEntity: E.TimeRouteEntity,
  title: 'Administracion Tiempo de Ruta',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administracion Tiempo de Ruta'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const IfacCRUD = (title: string, query: SimpleObject, ifacType: IfacType) =>
  generateCRUD({
    webEntity: E.IfacEntity,
    title,
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable={title}
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    additionalTopTableActions: [
      {
        component: <IfacFiltersConnected ifacType={ifacType} />
      }
    ],
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: { grouping: ['period'] }
  });

export const IfacSimulatedCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.IfacSimulatedEntity,
    title: 'Simulación IFAC',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: { grouping: ['type', 'period'] },
    bodyRowProps: ({ row }) => {
      const condition = row.original?.type === 'Simulado';
      return {
        sx: {
          backgroundColor: condition ? '#E2EFDA' : ''
        }
      };
    }
  });

export const FreightTimeMasterCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.FreightTimeMasterEntity,
    title: 'Calendario',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: true,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    },
    bodyRowProps: ({ row }) => {
      const isBusinessDay = row.original?.isBusinessDay;
      return {
        sx: {
          backgroundColor: isBusinessDay ? '#27C91E' : '#F76767'
        }
      };
    }
  });

export const ForeignExchangeCRUD = generateCRUD({
  webEntity: E.ForeignExchangeEntity,
  title: 'Real',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent titleTable='Real' auth={auth} tableData={tableData} isloading={isloading} />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: false,
    edit: true,
    editCondition: (row) => {
      const targetDateLocal = new Date(row.valueDate);
      const targetDateUTC = !isNaN(targetDateLocal.getTime())
        ? new Date(
            `${
              targetDateLocal.getUTCMonth() + 1
            }-${targetDateLocal.getUTCDate()}-${targetDateLocal.getUTCFullYear()}`
          )
        : undefined;

      const currentDate = new Date(
        `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`
      );

      const pastDate = new Date(currentDate);
      pastDate.setDate(pastDate.getDate() - 7);

      return targetDateUTC
        ? spanishDayNames[targetDateUTC.getDay()] === 'Jueves' &&
            targetDateUTC >= pastDate &&
            targetDateUTC <= currentDate
        : false;
    },
    select: { enable: false }
  },
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['valueDateMonth']
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.ForeignExchangeEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

export const ForeignExchangeEstimatedCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ForeignExchangeEstimatedEntity,
    title: 'Presupuesto',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const ForeignExchangeResumeCRUD = (additionalCardAlerts: CardAlertProps[]) =>
  generateCRUD({
    webEntity: E.ForeignExchangeResumeEntity,
    title: 'Resumen',
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Resumen'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
        omitGlobalDataCard={true}
        additionalCardAlerts={additionalCardAlerts}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['foreignExchangeType'],
      columnPinning: { left: ['foreignExchangeType', 'currencyName'] }
    },
    additionalTopTableActions: [
      {
        component: (
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.ForeignExchangeResumeEntity}
            viewsDate={['year']}
            omitZoneFilter={true}
          />
        )
      }
    ]
  });

export const ContingencyWithholdingInconsistenciesCRUD = generateCRUD({
  webEntity: E.ContingencyWithholdingInconsistenciesEntity,
  title: 'Inconsistencias de Retención por Contingencia',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Inconsistencias de Retención por Contingencia'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.ContingencyWithholdingInconsistenciesEntity}
          viewsDate={['year', 'month']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

export const FreightExtraInconsistenciesCRUD = generateCRUD({
  webEntity: E.FreightExtraInconsistenciesEntity,
  title: 'Inconsistencias de Extras',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Inconsistencias de Extras'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.FreightExtraInconsistenciesEntity}
          viewsDate={['year', 'month']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

export const CarrierRouteCarriageCRUD = generateCRUD({
  webEntity: E.CarrierRouteCarriageEntity,
  title: 'Administración Tarifas Transportistas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Administración Tarifas Transportistas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: true,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['carrierName', 'timeRouteIdCcu']
  },
  bodyRowProps: ({ row }) => {
    const dateValidFrom = new Date(
      `${new Date(row?.original?.dateValidFrom).getUTCMonth() + 1}-${new Date(
        row?.original?.dateValidFrom
      ).getUTCDate()}-${new Date(row?.original?.dateValidFrom).getUTCFullYear()}`
    );
    const dateValidTo = new Date(
      `${new Date(row?.original?.dateValidTo).getUTCMonth() + 1}-${new Date(
        row?.original?.dateValidTo
      ).getUTCDate()}-${new Date(row?.original?.dateValidTo).getUTCFullYear()}`
    );
    const valid = dateValidFrom < new Date() && dateValidTo > new Date();
    return {
      sx: valid
        ? {
            border: '2px solid green'
          }
        : {}
    };
  }
});

export const CarrierTypeCarriageCRUD = generateCRUD({
  webEntity: E.CarrierTypeCarriageEntity,
  title: 'Tipo Transportista',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Tipo Transportista'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  enableStickyFooter: true,
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const TariffCarriageCRUD = generateCRUD({
  webEntity: E.TariffCarriageEntity,
  title: 'Detalles Tarifas Transportistas',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Detalles Tarifas Transportistas'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true,
    select: { enable: false }
  },
  enableStickyFooter: true,
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['carrierName', 'timeRouteIdCcu']
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.TariffCarriageEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ]
});

export const ExtraCarriageCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ExtraCarriageEntity,
    title: 'Extras Acarreo',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['category']
    },
    enableStickyFooter: true
  });

export const ReportUENCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ReportUENEntity,
    title: 'Reporte UEN',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true
  });

export const ShipmentCarriageCRUD = (
  entity: WebEntity<ShipmentCarriage>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['carrierName']
    },
    bodyRowProps: ({ row }) => {
      const authorizedForPay = row?.original?.authorizedForPay;
      return {
        sx: authorizedForPay
          ? {}
          : {
              border: `2px solid red`
            }
      };
    },
    customExport: {
      headerStyle: {
        backgroundColor: '6AAA13',
        color: 'FFFFFF'
      }
    }
  });

export const ShipmentAuditingCarriageCRUD = (
  entity: WebEntity<ShipmentAuditingCarriage>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      columnPinning: {
        right: ['createdDateSource', 'action']
      },
      grouping: ['shipmentAuditingCarriageId']
    }
  });

export const ShipmentCarriagePXQCRUD = (
  entity: WebEntity<ShipmentCarriagePXQ>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['carrierName']
    }
  });

export const ResumeShipmentCarriageCRUD = (
  entity: WebEntity<ResumeShipmentCarriage>,
  title: string,
  query: SimpleObject
) =>
  generateCRUD({
    webEntity: entity,
    title,
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    initialState: {
      grouping: ['carrierTypeCarriageName']
    },
    enableStickyFooter: true
  });

export const PolynomialCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.PolynomialEntity,
    title: 'Polinomio',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const ProvisionCarriageCRUD = (query: SimpleObject) =>
  generateCRUD({
    webEntity: E.ProvisionCarriageEntity,
    title: 'Provision Acarreo',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true
  });

export const BudgetCarriageCRUD = (entity: WebEntity<BudgetCarriage>, query: SimpleObject) =>
  generateCRUD({
    webEntity: entity,
    title: 'Presupuesto Acarreo',
    query,
    customTopTable: () => <></>,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    },
    enableStickyFooter: true
  });

export const CarriageResumeCRUD = generateCRUD({
  webEntity: E.CarriageResumeEntity,
  title: 'Resumen Acarreo',
  customTopTable: ({ auth, tableData, isloading }) => (
    <TopTableComponent
      titleTable='Resumen Acarreo'
      auth={auth}
      tableData={tableData}
      isloading={isloading}
    />
  ),
  columnsHeaderStyle: columnsHeaderCCUStyle,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  additionalTopTableActions: [
    {
      component: (
        <DateAndGlobalZoneFiltersConnected
          webEntity={E.CarriageResumeEntity}
          viewsDate={['year']}
          omitZoneFilter={true}
        />
      )
    }
  ],
  initialState: {
    grouping: ['group'],
    columnPinning: {
      left: ['group', 'name']
    }
  }
});

export const ReporteGastosCRUD = (webEntity: WebEntity<ReporteGastos>, query: SimpleObject) =>
  generateCRUD({
    webEntity,
    title: 'Reporte Gastos',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reporte Gastos'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    enableStickyFooter: true,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const GastosPieChartCRUD = (webEntity: WebEntity<ReportePieChart>, query: SimpleObject) =>
  generateCRUD({
    webEntity,
    title: '',
    query,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    enableStickyFooter: true,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const GastosPieChartComparisonCRUD = (webEntity: WebEntity<ReportePieChart>) =>
  generateCRUD({
    webEntity,
    title: '',
    dummy: true,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const ReporteVolumenCRUD = (webEntity: WebEntity<ReporteVolumen>, query: SimpleObject) =>
  generateCRUD({
    webEntity,
    title: 'Reporte Volumen',
    query,
    customTopTable: ({ auth, tableData, isloading }) => (
      <TopTableComponent
        titleTable='Reporte Volumen'
        auth={auth}
        tableData={tableData}
        isloading={isloading}
      />
    ),
    columnsHeaderStyle: columnsHeaderCCUStyle,
    enableStickyFooter: true,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const VolumenPieChartCRUD = (webEntity: WebEntity<ReportePieChart>, query: SimpleObject) =>
  generateCRUD({
    webEntity,
    title: '',
    query,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    enableStickyFooter: true,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

export const VolumenPieChartComparisonCRUD = (webEntity: WebEntity<ReportePieChart>) =>
  generateCRUD({
    webEntity,
    title: '',
    dummy: true,
    columnsHeaderStyle: columnsHeaderCCUStyle,
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });
