import { ReporteVolumen } from '../types';
import { ExtendedColumnDef, WebEntity } from './types';

const ReporteVolumenEntity: WebEntity<ReporteVolumen> = {
  name: 'reporteVolumen',
  endpoint: 'reportes/volumen',
  referenceColumn: 'zoneGlobalName',
  tableColumns: []
};

export default ReporteVolumenEntity;

export const columns: ExtendedColumnDef<ReporteVolumen>[] = [
  {
    header: 'Zona Global',
    accessorKey: 'zoneGlobalName',
    columnType: 'text'
  },
  {
    header: 'Zona',
    accessorKey: 'zoneVolumeName',
    columnType: 'text'
  },
  {
    header: 'CD',
    accessorKey: 'distributionCenterVolumeName',
    columnType: 'text'
  }
];
